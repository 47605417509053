import { defineStore } from 'pinia'
import axios from '@/lib/axios'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'vue3-toastify'

export const useOneStore = defineStore('oneStore', {
  state: () => ({
    bearerToken: localStorage.getItem('token') || null,
    sessionToken: localStorage.getItem('session-token') || null,
    preferences: JSON.parse(localStorage.getItem('preferences')) || { theme: 'light', language: 'sk' },
    userId: localStorage.getItem('UID') || null,
    companyId: parseInt(localStorage.getItem('CID')) || null,
    roleId: parseInt(localStorage.getItem('RID')) || null,
    name: localStorage.getItem('name') || null,
    email: localStorage.getItem('email') || null,
    orders: [],
    plannedOrders: [],
    taxiDrivers: [],
    vehicles: [],
    myCompany: {},
    myCompanySettings: {},
    companyLicenses: [],
    licenses: [],
    myCurrentAddress: { address: '', location: { lat: null, lng: null } },
    addressSearchModalBreakpoint: 0.15,
    vehiclePositionUpdateReceived: false
  }),
  getters: {
    isLoggedIn: (state) => {
      if (!state.bearerToken) return false
      const decodedToken = jwtDecode(state.bearerToken)
      return decodedToken.exp > Date.now() / 1000
    },
    getMyName: (state) => state.name,
    getMyId: (state) => state.userId,
    getMyCompany: (state) => state.myCompany,
    getMyCompanyId: (state) => parseInt(state.companyId),
    getTaxiDrivers: (state) => state.taxiDrivers,
    getTaxiVehicles: (state) => state.vehicles,
    getOrders: (state) => state.orders,
    getPlannedOrders: (state) => state.plannedOrders,
    getLicenses: (state) => state.licenses,
    getMyLicenses: (state) => state.companyLicenses,
    getVehiclePositionUpdateReceived: (state) => state.vehiclePositionUpdateReceived
  },
  actions: {
    // #region AUTH
    async login(formData) {
      const response = await axios.post('public/login/taxi-admin', formData)
      if (response.status === 200) {
        const data = response.data
        const decodedToken = jwtDecode(data.token)

        this.bearerToken = data.token
        this.sessionToken = data.sessionToken
        this.preferences = data.preferences
        this.userId = decodedToken.payload.userId
        this.name = decodedToken.payload.name
        this.email = decodedToken.payload.email
        this.roleId = parseInt(decodedToken.payload.roleId)
        this.companyId = parseInt(decodedToken.payload.companyId)

        localStorage.setItem('token', data.token)
        localStorage.setItem('preferences', JSON.stringify(data.preferences))
        localStorage.setItem('session-token', data.sessionToken)

        localStorage.setItem('UID', decodedToken.payload.userId)
        localStorage.setItem('name', decodedToken.payload.name)
        localStorage.setItem('email', decodedToken.payload.email)
        localStorage.setItem('RID', decodedToken.payload.roleId)
        localStorage.setItem('CID', decodedToken.payload.companyId)

        return decodedToken?.payload || null
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async logout() {
      this.bearerToken = null
      this.sessionToken = null
      this.preferences = { theme: 'light', language: localStorage.getItem('t_lng') || 'sk' }
      this.userId = null
      this.name = null
      this.email = null
      this.roleId = null
      this.companyId = null

      const t_lng = localStorage.getItem('t_lng')
      const t_lastMapCenter = localStorage.getItem('t_lastMapCenter')
      const t_lastMapZoom = localStorage.getItem('t_lastMapZoom')

      localStorage.clear()

      if (t_lng) {
        localStorage.setItem('t_lng', t_lng)
      }
      if (t_lastMapCenter) {
        localStorage.setItem('t_lastMapCenter', t_lastMapCenter)
      }
      if (t_lastMapZoom) {
        localStorage.setItem('t_lastMapZoom', t_lastMapZoom)
      }
    },
    // #region DATA
    async getInitialData() {
      const response = await axios.post('taxi-admin/data/get-company-data', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        const { vehicles, taxiDrivers, myCompany, myCompanySettings, companyLicenses, licenses } = response.data
        this.taxiDrivers = taxiDrivers
        this.vehicles = vehicles
        this.myCompany = myCompany
        this.myCurrentAddress = myCompanySettings
        this.companyLicenses = companyLicenses
        this.licenses = licenses
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getCompanySettings() {
      const response = await axios.post('taxi-admin/company-settings/get-company-settings', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanySettings(companySettingsObject) {
      const response = await axios.post('taxi-admin/company-settings/edit-company-settings', { sessionToken: this.sessionToken, ...companySettingsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getVRPCredentials() {
      const response = await axios.post('taxi-admin/company-settings/get-vrp-credentials', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateVRPCredentials(vrpCredentialsObject) {
      const response = await axios.post('taxi-admin/company-settings/update-vrp-credentials', { sessionToken: this.sessionToken, companyId: this.companyId, ...vrpCredentialsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanyDetails(companyDetailsObject) {
      const response = await axios.post('taxi-admin/data/edit-my-company-details', { sessionToken: this.sessionToken, companyId: this.companyId, ...companyDetailsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getCompanyInvoices() {
      const response = await axios.post('taxi-admin/data/get-invoices-for-my-company', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region ORDERS
    async getCompanyOrders(searchParamsObject) {
      const response = await axios.post('taxi-admin/data/new-get-orders-data-from-to', { sessionToken: this.sessionToken, companyId: this.companyId, ...searchParamsObject })
      if (response.status === 200) {
        this.orders = response.data.orders
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getCompanyPlannedOrders(limiter) {
      const response = await axios.post('taxi-admin/data/get-planned-orders', { sessionToken: this.sessionToken, companyId: this.companyId, timeLimiterInMonths: limiter })
      if (response.status === 200) {
        this.plannedOrders = response.data.orders
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getLedgerEntriesForOrder(orderIdObject) {
      const response = await axios.post('taxi-admin/data/get-ledger-data-for-order', { sessionToken: this.sessionToken, companyId: this.companyId, ...orderIdObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getLocationHistoryForOrder(orderIdObject) {
      const response = await axios.post('taxi-admin/data/get-position-history-data-for-order', { sessionToken: this.sessionToken, companyId: this.companyId, ...orderIdObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createManualOrder(newOrderObject) {
      const response = await axios.post('taxi-admin/data/new-create-manual-order', { sessionToken: this.sessionToken, companyId: this.companyId, order: newOrderObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async uploadRecordingForManualOrder(formData) {
      formData.append('appUUID', import.meta.env.VITE_PUBLIC_APP_UUID)
      const response = await axios.post('public/upload/upload-voice-record-for-manual-order', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },

    async cancelPlannedOrder(order) {
      const response = await axios.post('taxi-admin/data/cancel-planned-order', { sessionToken: this.sessionToken, companyId: this.companyId, order })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region VEHICLES
    async getCompanyVehicles(searchParamsObject) {
      const response = await axios.post('taxi-admin/vehicles/get-vehicles', { sessionToken: this.sessionToken, companyId: this.companyId, ...searchParamsObject })
      if (response.status === 200) {
        this.vehicles = response.data.vehicles
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createCompanyVehicle(vehicleObject) {
      const response = await axios.post('taxi-admin/vehicles/create-vehicle', { sessionToken: this.sessionToken, companyId: this.companyId, ...vehicleObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanyVehicle(vehicleObject) {
      const response = await axios.post('taxi-admin/vehicles/edit-vehicle', { sessionToken: this.sessionToken, companyId: this.companyId, ...vehicleObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async deleteCompanyVehicle(vehicleObject) {
      const response = await axios.post('taxi-admin/vehicles/remove-vehicle', { sessionToken: this.sessionToken, companyId: this.companyId, ...vehicleObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region Taxios Sentinel
    async getSentinelVehicles(searchParamsObject) {
      const response = await axios.post('taxi-admin/fleet-management/get-fleet-vehicles', { sessionToken: this.sessionToken, companyId: this.companyId, ...searchParamsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async saveSentinelVehicle(data) {
      const response = await axios.post('taxi-admin/fleet-management/update-fleet-vehicle', { sessionToken: this.sessionToken, companyId: this.companyId, fleetVehicle: data })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getSentinelPositionHistory(data) {
      const response = await axios.post('taxi-admin/fleet-management/get-fleet-vehicle-position-history', { sessionToken: this.sessionToken, companyId: this.companyId, ...data })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getSentinelVehicleDriversHistory(data) {
      const response = await axios.post('taxi-admin/fleet-management/get-vehicle-drivers-history', { sessionToken: this.sessionToken, companyId: this.companyId, ...data })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async manualSentinelVehicleCheck(vehicleId) {
      console.log({ sessionToken: this.sessionToken, companyId: this.companyId, vehicleId })
      const response = await axios.post('taxi-admin/fleet-management/manual-check-mot-insurance-vignette', { sessionToken: this.sessionToken, companyId: this.companyId, vehicleId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region DRIVERS
    async getCompanyDrivers(searchParamsObject) {
      const response = await axios.post('taxi-admin/users/get-drivers', { sessionToken: this.sessionToken, companyId: this.companyId, ...searchParamsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createCompanyDriver(driverObject) {
      const response = await axios.post('taxi-admin/users/invite-driver', { sessionToken: this.sessionToken, companyId: this.companyId, ...driverObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanyDriver(driverObject) {
      const response = await axios.post('taxi-admin/users/edit-driver', { sessionToken: this.sessionToken, companyId: this.companyId, user: driverObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async deleteCompanyDriver(driverObject) {
      const response = await axios.post('taxi-admin/users/remove-driver', { sessionToken: this.sessionToken, companyId: this.companyId, ...driverObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async detachCompanyDriverFromVehicle(driverObject) {
      const response = await axios.post('taxi-admin/users/detach-driver-from-vehicle', { sessionToken: this.sessionToken, companyId: this.companyId, ...driverObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region TAXI ADMINS
    async getCompanyAdmins() {
      const response = await axios.post('taxi-admin/taxi-admin-users/get-taxi-admin-users', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createCompanyAdmin(adminObject) {
      adminObject.sessionToken = this.sessionToken
      adminObject.companyId = this.companyId

      const response = await axios.post('taxi-admin/taxi-admin-users/create-taxi-admin-user', adminObject)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanyAdmin(adminObject) {
      adminObject.sessionToken = this.sessionToken
      adminObject.companyId = this.companyId

      const response = await axios.post('taxi-admin/taxi-admin-users/edit-taxi-admin-user', adminObject)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async deleteCompanyAdmin(adminObject) {
      adminObject.sessionToken = this.sessionToken
      adminObject.companyId = this.companyId

      const response = await axios.post('taxi-admin/taxi-admin-users/delete-taxi-admin-user', adminObject)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region PHONES
    async getDriverPhoneNumbers() {
      const response = await axios.post('taxi-admin/phonie/get-driver-phone-numbers', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createDriverPhoneNumber(phoneNumberObject) {
      const response = await axios.post('taxi-admin/phonie/create-driver-phone-number', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneNumberObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateDriverPhoneNumber(phoneNumberObject) {
      const response = await axios.post('taxi-admin/phonie/update-driver-phone-number', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneNumberObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async deleteDriverPhoneNumber(phoneNumberObject) {
      const response = await axios.post('taxi-admin/phonie/delete-driver-phone-number', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneNumberObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getCompanyPhoneSettings() {
      const response = await axios.post('taxi-admin/phonie/get-company-phone-numbers-and-settings', { sessionToken: this.sessionToken, companyId: this.companyId })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateCompanyPhoneSettings(updateObject) {
      const response = await axios.post('taxi-admin/phonie/update-company-phone-numbers-and-settings', { sessionToken: this.sessionToken, companyId: this.companyId, ...updateObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region PHONE DIRECTORY
    async getPhoneDirectory(searchParamsObject) {
      const response = await axios.post('taxi-admin/phone-directory/get-combined-phone-directory', { sessionToken: this.sessionToken, companyId: this.companyId, ...searchParamsObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async createItemInPhoneDirectory(phoneDirectoryObject) {
      const response = await axios.post('taxi-admin/phone-directory/create-item-in-phone-directory', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneDirectoryObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async updateItemInPhoneDirectory(phoneDirectoryObject) {
      const response = await axios.post('taxi-admin/phone-directory/update-item-in-phone-directory', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneDirectoryObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async deleteItemInPhoneDirectory(phoneDirectoryObject) {
      const response = await axios.post('taxi-admin/phone-directory/remove-item-from-phone-directory', { sessionToken: this.sessionToken, companyId: this.companyId, ...phoneDirectoryObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region STATS
    async getFirstGlanceStatistics(dateObject) {
      const response = await axios.post('taxi-admin/data/get-first-glance-statistics', { sessionToken: this.sessionToken, companyId: this.companyId, ...dateObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getCompanyStatistics(dateObject) {
      const response = await axios.post('taxi-admin/data/get-company-statistics', { sessionToken: this.sessionToken, companyId: this.companyId, ...dateObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async getWorkingHours(dateObject) {
      const response = await axios.post('taxi-admin/data/get-all-driver-hours-from-to', { sessionToken: this.sessionToken, companyId: this.companyId, ...dateObject })
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    // #region SOCKET
    async updateVehiclePosition(event) {
      this.vehiclePositionUpdateReceived = true
      setTimeout(() => {
        this.vehiclePositionUpdateReceived = false
      }, 100)
      const vehicleId = event.currentVehicleId
      const positionUpdate = event.position
      const speedUpdate = event.speed
      const indexOfVehicle = this.vehicles.findIndex((vehicle) => vehicle.vehicleId === vehicleId)

      if (indexOfVehicle !== -1) {
        this.vehicles[indexOfVehicle].currentLat = positionUpdate.lat
        this.vehicles[indexOfVehicle].currentLng = positionUpdate.lng
        this.vehicles[indexOfVehicle].speed = speedUpdate
      }
    },
    async updateDriverStatus(event) {
      const indexOfDriver = this.taxiDrivers.findIndex((driver) => driver.userId === event.userId)
      if (indexOfDriver !== -1) {
        let driver = this.taxiDrivers[indexOfDriver]

        if (event.status === 'break-true') {
          driver.onBreak = true
        } else if (event.status === 'break-false') {
          driver.onBreak = false
        }

        if (event.status === 'occupied-true') {
          driver.occupied = true
        } else if (event.status === 'occupied-false') {
          driver.occupied = false
        }

        if (event.status === 'onCall-true') {
          driver.onCall = true
        } else if (event.status === 'onCall-false') {
          driver.onCall = false
        }

        if (event.status === 'callDisable-false') {
          driver.disableCalls = false
        } else if (event.status === 'callDisable-true') {
          driver.disableCalls = true
        }

        if (event.status === 'voiceChatDisable-false') {
          driver.voiceChatDisable = false
        } else if (event.status === 'voiceChatDisable-true') {
          driver.voiceChatDisable = true
        }

        this.taxiDrivers[indexOfDriver] = driver
      }
    },
    async handleNewOrder(event) {
      const findIndexOfOrder = this.orders.findIndex((order) => order.orderId === event.orderDetail.orderId)

      if (findIndexOfOrder !== -1) {
        this.orders[findIndexOfOrder] = { ...event.orderDetail }
      } else {
        this.orders.unshift(event.orderDetail)
      }
    },
    async handleCarOccupancyUpdate(event) {
      if (event.typeOfEvent === 'endForShift') {
        const indexOfVehicle = this.vehicles.findIndex((vehicle) => vehicle.vehicleId === event.currentVehicleId)
        if (indexOfVehicle !== -1) {
          toast.error(`Logout: ${this.taxiDrivers.find((driver) => driver.userId === event.userId)?.name}, ${this.vehicles[indexOfVehicle].name}`, { autoClose: 4000, theme: 'colored', position: toast.POSITION.TOP_RIGHT })
          this.vehicles[indexOfVehicle].currentDriver = null
          this.vehicles[indexOfVehicle].currentDriverId = null
        }
      } else if (event.typeOfEvent === 'selectedForShift') {
        const indexOfVehicle = this.vehicles.findIndex((vehicle) => vehicle.vehicleId === event.carSelected?.vehicleId)
        if (indexOfVehicle !== -1) {
          toast.success(`New login: ${event.userName}, ${this.vehicles[indexOfVehicle].name}`, { autoClose: 4000, theme: 'colored', position: toast.POSITION.TOP_RIGHT })
          this.vehicles[indexOfVehicle].currentDriver = event.userName
          this.vehicles[indexOfVehicle].currentDriverId = event.userId
        }
      }
    }
  }
})
