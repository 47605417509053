export default {
  title: 'Taxios Admin',
  entries: 'zapiski',
  email: 'Email',
  emails: 'Emaily',
  password: 'Hasło',
  select: 'Wybierz',
  cancel: 'Anuluj',
  close: 'Zamknij',
  submit: 'Wyślij',
  confirm: 'Potwierdź',
  edit: 'Edytuj',
  selectOne: 'Wybierz',
  saveChanges: 'Zapisz zmiany',
  save: 'Zapisz',
  startTyping: 'Zacznij pisać',
  changeOil: 'Wymień olej',
  download: 'Pobierz',
  loadData: 'Załaduj dane',
  required: 'Nie może być puste',
  search: 'Szukaj',
  from: 'Od',
  to: 'Do',
  anyDriver: 'Dowolny kierowca',
  driver: 'Kierowca',
  vehicle: 'Pojazd',
  months: 'miesiące',
  month: 'miesiąc',
  weeks: 'tygodnie',
  today: 'Dziś',
  yesterday: 'Wczoraj',
  days: 'Dni',
  everyDay: 'Kazdy dzien',
  years: 'Lata',
  minute: 'Minuta',
  minutes: 'Minuty',
  minutes2: 'Minut',
  hour: 'Godzina',
  hours: 'Godziny',
  seconds: 'Sekundy',
  unlimited: 'Nieograniczony',
  defaultAllDriver: 'Dowolny kierowca',
  allOrders: 'Wszystkie zamówienia',
  true: 'Tak',
  false: 'Nie',
  enabled: 'Włączone',
  disabled: 'Wyłączone',
  noLimit: 'Brak limitu',
  uploadNewPicture: 'Załaduj nowy',
  cropImage: 'Przytnij obrazek',
  crop: 'Potwierdź',
  remove: 'Usuń',
  mustBeValidEmail: 'Musi być prawidłowy email',
  fieldRequired: 'Pole jest wymagane',
  minCharacters: 'Minimalnie 5 znaków',
  minCharacters1: 'Minimalnie 1 znak',
  valueMustBeAtLeastOne: 'Musi być liczba większa niż 1',
  valueMustBeAtLeastMinus1: 'Musi być liczba większa niż -1',
  mustBeBoolean: 'Musi być Tak lub Nie',
  mustBeNumber: 'Musi być liczba między 1 a 20',
  mustBePhoneNumber: 'Numer telefonu musi być w formacie +48XXXXXXXXX',
  voiceRecordingOrFromToIsRequired: 'Wymagana jest "Notatka głosowa" lub adres "Od" i "Do"',
  CzechRepublic: 'Czechy',
  Slovakia: 'Słowacja',
  Poland: 'Polska',
  Hungary: 'Węgry',
  Hungarian: 'Węgierski',
  Slovak: 'Słowacki',
  Polish: 'Polski',
  Czech: 'Czeski',
  setupNotifications: 'Ustawienia powiadomień',
  addEmail: 'Dodaj email',
  enterEmail: 'Wpisz email',
  noEmails: 'Adresy e-mail do otrzymywania powiadomień nie zostały ustawione',
  setupEmails: 'Ustaw e-maile',
  errorMessages: {
    invalidCredentials: 'Nieprawidłowy email lub hasło',
    genericError: 'Wystąpił błąd. Kod statusu: {status}',
    socketDisconnectedPleaseRefreshPage: 'Rozłączono z serwerem, proszę odświeżyć stronę',
    notFound: 'Nie mogliśmy znaleźć strony, której szukasz.',
    404: 'Strona nie znaleziona ⚠️'
  },
  orderStatuses: {
    INCOMING: 'Przychodzące',
    CANCELLED: 'Anulowane',
    ANSWERED: 'Odebrane',
    PROGRESS: 'W trakcie',
    COMPLETED: 'Zakończone',
    ANSWEREDFORWARDED: 'Przekierowane',
    UNANSWERED: 'Nieodebrane',
    OWNORDER: 'Własne zamówienie',
    AWAITINGCUSTOMER: 'Oczekuje na klienta',
    PLANNED: 'Zaplanowane',
    PROCESSED: 'Przetworzono',
    CUSTOMERAPPCREATED: 'Aplikacja utworzona',
    TIMEDOUT: 'Aplikacja nieodebrana',
    'OFFLINE-MISSED': 'OFFLINE'
  },
  driverStatuses: {
    free: 'Wolny',
    occupied: 'Zajęty',
    onBreak: 'Na przerwie',
    onCall: 'Na rozmowie'
  },
  login: {
    header: 'Zaloguj się do swojego konta i rozpocznij przygodę',
    login: 'Zaloguj się'
  },
  menu: {
    dividers: {
      vehiclesAndDrivers: 'Pojazdy i kierowcy',
      phones: 'Telefony',
      myCompany: 'Moja firma',
      app: 'Aplikacja'
    },
    tooltip: {
      vehicles: 'Tutaj możesz utworzyć i edytować pojazdy, z których będą korzystać Twoi kierowcy',
      drivers: 'Tutaj możesz utworzyć i edytować profile kierowców. Te profile będą używane do logowania i możesz również ustawić limity na rozmowy i zamówienia dla każdego kierowcy',
      vehiclePhones: 'Tutaj musisz ustawić numery telefonów, które Twoi kierowcy będą używać do odbierania rozmów od klientów',
      phoneBook: 'Zarządzaj kontaktami klientów: zapisuj klientów, aby przypisać ulubionych kierowców lub blokuj klientów, aby uniemożliwić im dzwonienie',
      switchBoard: 'Zobacz i zarządzaj główną linią taxi. Upewnij się, że wszystkie telefony taxi są przekierowane tutaj, aby kierowcy mogli odbierać rozmowy. Ustaw przychodzące rozmowy dla firmy',
      taxiosSentinel: 'Monitorowanie pozycji taksówki, statusu badań technicznych, emisji i innych danych.'
    },
    dashboard: 'Dashboard',
    statistics: 'Statystyki',
    orders: 'Zamówienia',
    map: 'Mapa',
    vehicles: 'Pojazdy',
    drivers: 'Kierowcy',
    phonesInVehicles: 'Telefony w pojazdach',
    phoneBook: 'Książka telefoniczna',
    phoneSwitchboard: 'Centrala taxi',
    invoices: 'Faktury',
    settings: 'Ustawienia',
    logout: 'Wyloguj się',
    help: 'Pomoc',
    customerApp: 'Aplikacja Klienta',
    taxiosDriver: 'Aplikacja Kierowcy Taxios',
    fareSettings: 'Ustawienia taryfy',
    vrpSK: 'VRP (SK)',
    taxiosSentinel: 'Taxios Sentinel',
    notifications: 'Powiadomienia'
  },
  dashboard: {
    loggedInDrivers: 'Kierowcy online',
    viewingDataFor: 'Wyświetlanie statystyk dla:',
    completed: 'ukończone',
    cancelled: 'anulowane',
    total: 'łącznie',
    todayCompleted: 'Dziś ukończone',
    todayCancelled: 'Dziś anulowane',
    todayTotal: 'Dziś łącznie',
    planned: 'Zaplanowane',
    welcomeBack: 'Witamy z powrotem',
    welcomeText: 'Przygotowaliśmy nowy panel, który jest pełen nowych funkcji i pomaga lepiej zarządzać firmą taksówkową! Ciesz się i daj nam znać, jak ci idzie',
    analyticsMonthlyView: {
      header: 'Analiza liczby zamówień: Roczny i miesięczny przegląd'
    }
  },
  orders: {
    header: 'Zamówienia',
    openOrderDetailModal: 'Szczegóły zamówienia',
    displayOnlyPlannedOrders: 'Wyświetlaj tylko zaplanowane zamówienia',
    saveCustomer: 'Zapisz klienta',
    editCustomer: 'Edytuj klienta',
    cancelPlannedOrder: 'Anulować zaplanowane zamówienie',
    cancelPlannedOrderText: 'Czy na pewno chcesz anulować to zaplanowane zamówienie?',
    addOrder: 'Dodaj zam.',
    search: {
      customerPhoneNumber: 'Klient (numer lub imię)',
      driverName: 'Imię kierowcy',
      timeFrom: 'Od',
      timeTo: 'Do',
      orderStatus: 'Status zamówienia',
      timeRange: 'Data / Czas',
      range: {
        today: 'Dziś',
        yesterday: 'Wczoraj',
        last7: 'Ostatnie 7 dni',
        thisMonth: 'Ten miesiąc',
        lastMonth: 'Ostatni miesiąc',
        custom: 'Własny zakres'
      }
    },
    itemsPerPage: 'Elementów na stronę',
    orderId: 'ID zamówienia',
    orderStarted: 'Początek',
    orderCompleted: 'Zakończone',
    length: 'Długość',
    orderStatus: 'Status',
    driver: 'Kierowca',
    customer: 'Klient',
    options: 'Opcje',
    recordsFound: 'znalezione zapisy',
    callRecording: 'Nagrywanie rozmowy',
    ownOrder: 'Własne zamówienie',
    from: 'Miejsce odbioru',
    to: 'Miejsce docelowe',
    modal: {
      header: 'Szczegóły zamówienia',
      order: 'Zamówienie',
      incomingCall: 'Przychodząca rozmowa',
      tryingDifferentDriver: 'Próbuję innego kierowcy',
      unansweredCall: 'Nieodebrana rozmowa',
      orderCancelled: 'Zam. Anulowane',
      callAnswered: 'Rozmowa Odebrana',
      forwardedOrder: 'Zam. Przekierowane',
      orderInProgress: 'Zam. W trakcie',
      orderCompleted: 'Zakończone',
      didNotAnswered: 'Nie odebrał telefonu',
      ownOrder: 'Własne zam.',
      customer: 'Klient',
      driver: 'Kierowca',
      toDriver: 'Do kierowcy',
      fromDriver: 'Od kierowcy',
      plannedTime: 'Zaplanowane na',
      sentToDriver: 'Wysłane do kierowcy',
      plannedOrder: 'Zam. Zaplanowane',
      recovered: 'Zam. Odzyskane',
      adminCreated: 'Utworzone przez admina',
      dispatcherCreated: 'Utworzone przez dyspozytora',
      reminder: 'Przypomnienie'
    },
    manualOrderModal: {
      header: 'Nowe zamówienie',
      customerPhoneNumber: 'Numer telefonu klienta',
      plannedOrderFlag: 'Zaplanowane zamówienie',
      from: 'Od',
      to: 'Do',
      driver: 'Kierowca',
      note: 'Notatka',
      plannedToTimeStamp: 'Planowany czas',
      remindBeforeStartTimeInMinutes: 'Przypomnij przed rozpoczęciem w minutach',
      startsNow: 'zamówienie zaczyna się teraz',
      startsLater: 'zamówienie zaczyna się później',
      recording: 'Notatka głosowa',
      record: 'Nagraj',
      delete: 'Usuń',
      save: 'Zapisz',
      stop: 'Stop',
      recordingAdded: 'Dodano notatkę głosową'
    }
  },
  statistics: {
    labelDateRange: 'Zakres dat do wyświetlania statystyk',
    completed: 'Ukończone zamówienia',
    cancelled: 'Anulowane zamówienia',
    total: 'Łącznie',
    averageOrderTime: 'Średni czas zamówienia',
    dailyOrdersHeader: 'Analiza liczby zamówień: Dzienny przegląd anulowane vs zakończone',
    completedOrderPerDriverHeader: 'Analiza ukończonych zamówień na kierowcę',
    completedOrderPerDriverVsWorkedHoursHeader: 'Ukończone zamówienia na kierowcę vs przepracowane godziny',
    workedHoursPerDriver: 'Przepracowane godziny na kierowcę',
    someStatsNotVisibleMdAndDown: 'Niektóre statystyki nie są widoczne na urządzeniach mobilnych.',
    worstVsBestDriverHeader: 'Najgorszy vs Najlepszy kierowca dnia'
  },
  taxiDrivers: {
    header: 'Kierowcy taxi',
    picture: 'Obrazek',
    name: 'Imię',
    email: 'Email',
    usingPhone: 'Używa telefonu',
    loggedIn: 'Zalogowany',
    lastLogin: 'Ostatnie logowanie',
    vehicle: 'Używa pojazdu',
    maxOrders: 'Maksymalna liczba zamówień',
    allowForwards: 'Zezwól na przekierowania',
    disableCalls: 'Wyłącz rozmowy',
    password: 'Hasło',
    options: 'Opcje',
    addDriver: 'Dodaj kierowcę',
    currentlyHave: 'Masz',
    drivers: 'kierowców',
    maxNumOfConcurrentOrdersDescription: 'Maksymalna liczba jednoczesnych zamówień, które kierowca może mieć na raz',
    allowForwardsFromOthersWhenMaxOrdersReachedDescription: 'Zezwól innym kierowcom na przekierowanie zamówień do kierowcy, który osiągnął maksymalną liczbę zamówień (pole Maksymalna liczba zamówień musi być aktywowane)',
    disableCallsDescription: 'Kierowca będzie miał wyłączone przychodzące rozmowy, inni mogą nadal przekierowywać zamówienia do niego, a on będzie mógł dzwonić do klientów ze swojego aktywnego zamówienia',
    modal: {
      titleAdd: 'Dodaj kierowcę',
      titleEdit: 'Edytuj kierowcę',
      removeDriver: 'Usuń kierowcę'
    }
  },
  taxiVehicles: {
    header: 'Pojazdy taxi',
    addVehicle: 'Dodaj pojazd',
    name: 'Imię',
    plate: 'Tablica rejestracyjna',
    model: 'Model',
    manufacturer: 'Producent',
    year: 'Rok',
    color: 'Kolor',
    fuel: 'Paliwo',
    currentDriver: 'Obecny kierowca',
    seats: 'Siedzenia',
    options: 'Opcje',
    currentlyHave: 'Masz',
    vehicles: 'pojazdy',
    removeDriverFromACarDescription: 'Spowoduje to wylogowanie kierowcy i pojazd będzie wolny!',
    youHaveRemovedDriverFromVehicle: 'Kierowca został odłączony od pojazdu',
    vehicleKm: 'Kilometry',
    vehicleKm2: 'Przejechane kilometry',
    lastOilChange: 'Olej zmieniony',
    vignette: 'Winieta',
    mot: 'Przegląd',
    insurance: 'OC',
    motStatus: 'BT',
    emissionsStatus: 'EK',
    lastOilChange2: 'Ostatnia wymiana oleju',
    oilChangeIntervals: 'Interwały wymiany oleju',
    newOilChange: 'Wymiana oleju',
    howMuchKmOilChange: 'Przy ilu km został wymieniony olej',
    dateWhenOilChanged: 'Data wymiany oleju',
    vehicleData: 'Ogólne dane pojazdu',
    vehicleHistory: 'Historia lokalizacji pojazdu',
    driverHistory: 'Historia kierowców',
    sentinelNotificationEmails: 'Powiadomienia e-mailowe Sentinel',
    sentinelNotificationEmailsDescription: 'Wprowadź adresy e-mail, aby otrzymywać powiadomienia o wygasaniu przeglądu technicznego, emisji, winiety oraz OC (ubezpieczenie).',
    validFrom: 'Ważne od',
    validTill: 'Ważne do',
    lastCheck: 'Ostatnia kontrola',
    timeDifference: 'Różnica czasu między "od" a "do" może wynosić maksymalnie 24 godzin',
    noVignette: 'Brak winiety. Synchronizacja z rządowymi stronami odbywa się co 24 godziny, ale czasami może trwać do 5 dni.',
    motCheck: 'Przegląd techniczny odbywa się co 24 godziny, ale czasami może trwać do 5 dni.',
    modal: {
      removeVehicle: 'Usuń pojazd',
      titleEdit: 'Edytuj pojazd',
      titleAdd: 'Dodaj pojazd',
      driverLogout: 'Wyloguj kierowcę i zwolnij pojazd?',
      willBeLoggedOut: 'zostanie wylogowany',
      willBeFreed: 'zostanie zwolniony'
    }
  },
  taxiPhones: {
    header: 'Telefony w pojazdach',
    addPhone: 'Dodaj telefon',
    name: 'Imię',
    phoneNumber: 'Numer telefonu',
    registered: 'Zarejestrowany',
    note: 'Notatka',
    phoneId: 'ID telefonu',
    version: 'Wersja',
    options: 'Opcje',
    modal: {
      titleAdd: 'Dodaj numer telefonu',
      titleEdit: 'Edytuj numer telefonu',
      removePhoneNumbers: 'Usuń numer telefonu'
    }
  },
  taxiPhoneDirectory: {
    header: 'Książka telefoniczna',
    addPhone: 'Zapisz nowego klienta',
    name: 'Imię',
    phoneNumber: 'Numer telefonu',
    favoriteDriver: 'Ulubiony kierowca',
    note: 'Notatka',
    blocked: 'Zablokowane',
    options: 'Opcje',
    modal: {
      titleAdd: 'Zapisz nowego klienta',
      titleEdit: 'Edytuj zapisanego klienta',
      doesNotHaveFavoriteDriver: 'Nie ma ulubionego kierowcy'
    }
  },
  taxiSwitchBoard: {
    header: 'Centrala taxi',
    headerStatistics: 'Statystyki centrali taxi',
    taxiNumber: 'Numer telefonu',
    phoneNumberDescription: 'Numer telefonu, na który należy przekierować wszystkie rozmowy przychodzące do Twojej firmy',
    callMaxTimeout: 'Maksymalny czas dzwonienia',
    fallBackNumber: 'Zapasowy numer telefonu',
    required: 'Pole jest wymagane',
    callMaxTimeoutError: 'Limit czasu musi wynosić od 10 do 20 sekund',
    fallBackNumberError: 'Numer musi zaczynać się od +'
  },
  taxiInvoices: {
    header: 'Moje faktury',
    invoiceName: 'Nazwa faktury',
    invoiceNumber: 'Numer faktury',
    periodMonth: 'Miesiąc okresu',
    issueDate: 'Data wystawienia',
    paymentDue: 'Termin płatności',
    paid: 'Data zapłaty',
    totalWithoutTax: 'Całkowita kwota bez podatku',
    taxAmount: 'Kwota podatku',
    total: 'Łącznie',
    options: 'Opcje',
    unpaid: 'Niezapłacone',
    info: 'Faktury są wysyłane na Twój adres email (podany w',
    settings: 'ustawieniach',
    inTabInvoiceDetails: 'w zakładce dane do faktury)',
    everyMonth: 'każdego 15. dnia miesiąca i muszą być opłacone w ciągu 15 dni (zazwyczaj 1. dnia miesiąca, za który faktura jest wystawiona)'
  },
  taxiosCustomerApp: {
    header: 'Aplikacja Taxios dla klientów',
    pleaseContactUs1: 'Jeśli chcesz mieć własną aplikację (oczywiście z Twoim logo i kolorami :) i dostępną na Apple i Androida), w której Twoi klienci mogą zamawiać taksówki bez konieczności dzwonienia do Ciebie, skontaktuj się z nami przez email ',
    pleaseContactUs2: ' a my pomożemy Ci to zrealizować.'
  },
  taxiosDriverApp: {
    header: 'Ustawienia aplikacji kierowcy Taxios',
    minLoggedDrivers: 'Minimalna liczba zalogowanych kierowców',
    maxDriversOnBreak: 'Maksymalna liczba kierowców na przerwie',
    maxDriversDisabledCalls: 'Maksymalna liczba kierowców z wyłączonymi rozmowami',
    showEachOtherStatistics: 'Pokaż statystyki innych',
    showEachOtherStatisticsDescription: 'Jeśli wybrano "Tak", kierowcy mogą zobaczyć statystyki innych kierowców w menu bocznym pod "Nasi kierowcy". Jeśli wybrano "Nie", statystyki nie będą dla nich widoczne.',
    voiceChatButtonMode: 'Tryb przycisku rozmowy głosowej',
    voiceChatButtonModeDescription: 'Kiedy wybrano "PTT", kierowca musi nacisnąć i przytrzymać przycisk, aby mówić, a zwolnić go, gdy skończy mówić. Kiedy wybrano "TOGGLE", jedno naciśnięcie aktywuje czat głosowy na określony czas, po czym zostanie on automatycznie zakończony.',
    voiceChatToggleTimeLimiter: 'Limit czasowy rozmowy głosowej',
    voiceChatToggleTimeLimiterDescription: 'Ustawia czas trwania (w sekundach), przez jaki czat głosowy pozostaje aktywny w trybie TOGGLE po naciśnięciu przycisku.',
    maxAllowedCancels: 'Maksymalna liczba dozwolonych anulowań',
    maxAllowedCancelsDescription: 'Ogranicza, ile razy kierowca może anulować zamówienie i odzyskać swoje pierwsze miejsce w kolejce. Po osiągnięciu limitu pozostaje na końcu kolejki i musi czekać na swoją następną kolej.',
    maxAllowedForwards: 'Maksymalna liczba dozwolonych przekierowań',
    maxAllowedForwardsDescription: 'Ogranicza, ile razy kierowca może przekazać zamówienie koledze i odzyskać swoje pierwsze miejsce w kolejce. Po osiągnięciu limitu pozostaje na końcu kolejki i musi czekać na swoją następną kolej.',
    maxAllowedMissedCalls: 'Maksymalna liczba dozwolonych nieodebranych rozmów',
    maxAllowedMissedCallsDescription: 'Ogranicza, ile połączeń może kierowca przegapić, zanim zostanie przesunięty na koniec kolejki. Po osiągnięciu limitu musi czekać na swoją następną kolej.',
    maxAllowedTransactionsTogether: 'Maksymalna liczba dozwolonych transakcji jednocześnie',
    maxAllowedLengthOfOrderInSecondsToResetQueue: 'Maksymalna długość zamówienia (w sekundach) do resetowania kolejki',
    maxAllowedLengthOfOrderInSecondsToResetQueueDescription:
      'Ustawia limit czasu (w sekundach), w którym kierowca może anulować zamówienie i wrócić na swoje pierwotne pierwsze miejsce w kolejce. Jeśli kierowca pracuje nad zamówieniem dłużej niż ten limit, anulowanie zamówienia nie zresetuje jego pozycji w kolejce.',
    pushToTalk: 'Naciśnij i mów',
    toggleToTalk: 'Naciśnij raz, potem mów aż do wygaśnięcia limitu czasu',
    driversManagement: 'Zarządzanie kierowcami',
    voiceChatSettings: 'Ustawienia rozmowy głosowej',
    phoneQueueSettings: 'Ustawienia kolejki telefonicznej',
    lastUpdated: 'Ostatnio zmienione',
    showCustomerNumberToTheDriver: 'Wyświetl numer klienta kierowcy',
    showCustomerNumberToTheDriverDescription: 'Jeśli wybrano "Tak", kierowcy mogą zobaczyć numer klienta w aplikacji. Jeśli wybrano "Nie", zobaczą tylko przycisk do oddzwonienia.',
    anybodyCanEditPlannedOrder: 'Każdy kierowca może edytować zaplanowane zamówienia',
    anybodyCanEditPlannedOrderDescription: 'Jeśli wybrano "Tak", każdy kierowca może edytować dowolne zaplanowane zamówienie. Jeśli wybrano "Nie", tylko administrator lub kierowca, który stworzył zamówienie, może je edytować.',
    allowDriverToSaveCustomer: 'Zezwól kierowcy na zapisanie klienta',
    allowDriverToSaveCustomerDescription: 'Jeśli zostanie wybrana opcja "Tak", kierowcy będą mogli zapisywać numery telefonów klientów w książce telefonicznej.',
    allowDriverToBlockCustomer: 'Zezwól kierowcy na zablokowanie klienta',
    allowDriverToBlockCustomerDescription:
      'Jeśli zostanie wybrana opcja "Tak", kierowcy będą mieli możliwość zablokowania numeru telefonu klienta podczas zapisywania go w książce telefonicznej. Jeśli klient zostanie zablokowany, nie będzie mógł skontaktować się z Twoją firmą taksówkową przy następnej próbie połączenia.'
  },
  settings: {
    admins: 'Administratorzy',
    companyDetails: 'Szczegóły firmy',
    invoiceDetails: 'Dane do faktury',
    myInvoices: 'Moje fakuty',
    name: 'Nazwa taxi',
    companyName: 'Nazwa firmy',
    companyAddress: 'Adres',
    companyAddress2: 'Adres 2',
    companyAddress3: 'Adres 3',
    companyCity: 'Miasto',
    companyPostCode: 'Kod pocztowy',
    companyEmail: 'Email',
    companyPhone: 'Telefon',
    companyRegisteredFrom: 'Zarejestrowano od',
    companyCountry: 'Kraj',
    companyIdentificationNumber: 'Numer identyfikacyjny',
    companyTaxNumber: 'NIP',
    invoiceLanguage: 'Język faktury',
    password: 'Hasło',
    role: 'Rola',
    email: 'Email',
    options: 'Opcje',
    admin: 'Admin',
    dispatchAgent: 'Dyspozytor',
    lastLoggedIn: 'Ostatnie logowanie',
    addAdmin: 'Dodaj nowego admina',
    adminName: 'Nazwa administratora',
    modal: {
      titleAdd: 'Dodaj administratora',
      titleEdit: 'Edytuj administratora',
      removeAdmin: 'Usuń administratora'
    }
  },
  taxiosVCR: {
    header: 'Integracja VRP 2',
    subheader: '(VRP SK)',
    enableVRP: 'Włącz integrację VRP do aplikacji Taxios Driver?'
  },
  fareSettings: {
    header: 'Ustawienia taryfy'
  },
  help: {
    header: 'Pomoc',
    status: 'Status',
    socketStatus: 'Połączenie z serwerem ',
    radioSocketStatus: 'Połączenie z serwerem radiowym '
  },
  logout: {
    header: 'Wyloguj się',
    areYouSure: 'Czy na pewno chcesz się wylogować?',
    logoutButton: 'Wyloguj się'
  },
  taxiosNotifications: {
    header: 'Ustawienia powiadomień',
    notificationsEmails: 'Twoje e-maile, na które wysyłamy powiadomienia',
    drivers: 'Kierowcy',
    notifyOnDriverLogin: 'Powiadom o logowaniu kierowcy',
    notifyOnDriverLogout: 'Powiadom o wylogowaniu kierowcy',
    notifyWhenDriverOnBreak: 'Powiadom, gdy kierowca zacznie przerwę',
    notifyWhenDriverEndsBreak: 'Powiadom, gdy kierowca zakończy przerwę',
    notificationsEmailsForDrivers: 'E-maile do powiadomień o kierowcach',
    orders: 'Zamówienia',
    notifyOnMissedOrderCall: 'Powiadom, gdy kierowca nie odbierze połączenia',
    notifyOnMissedPlannedOrder: 'Powiadom, gdy kierowca nie podejmie działań w sprawie planowanego zamówienia',
    notifyOnPlannedOrderCreated: 'Powiadom, gdy kierowca utworzy planowane zamówienie',
    notifyOnCompletedOrder: 'Powiadom, gdy kierowca zakończy zamówienie',
    taxiosSentinel: 'Taxios Sentinel',
    taxiosSentinelNotifications: 'Powiadomienia Taxios Sentinel',
    taxiosSentinelNotificationsDescription: `
      Otrzymuj terminowe powiadomienia od Taxios Sentinel, aby mieć pewność, że konserwacja pojazdu jest na bieżąco. 
      <br /><br />
      <strong>Wymiana oleju:</strong> Powiadomienia będą wysyłane w następujących odstępach, odliczając do kolejnej wymiany oleju:
      <ul>
        <li>1. powiadomienie przy pozostałych 2000 km</li>
        <li>2. powiadomienie przy pozostałych 1000 km</li>
        <li>3. powiadomienie przy pozostałych 100 km</li>
        <li>Codzienne przypomnienia będą kontynuowane, dopóki wymiana oleju nie zostanie potwierdzona w Taxios Sentinel</li>
      </ul>
      <br />
      <strong>Przegląd/Emisje:</strong> Powiadomienia będą wysyłane:
      <ul>
        <li>1. powiadomienie 2 miesiące przed</li>
        <li>2. powiadomienie 1 miesiąc przed</li>
        <li>3. powiadomienie 1 tydzień przed</li>
      </ul>
    `
  }
}
