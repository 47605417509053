export default {
  title: 'Taxios Admin',
  entries: 'záznamy',
  email: 'Email',
  emails: 'Emaily',
  password: 'Heslo',
  select: 'Vybrat',
  cancel: 'Zrušit',
  close: 'Zavřít',
  submit: 'Odeslat',
  confirm: 'Potvrdit',
  save: 'Uložit',
  edit: 'Upravit',
  selectOne: 'Vybrat',
  changeOil: 'Vyměnit olej',
  saveChanges: 'Uložit změny',
  startTyping: 'Začněte psát',
  download: 'Stáhnout',
  loadData: 'Načíst údaje',
  required: 'Nemůže být prázdné',
  search: 'Hledat',
  from: 'Od',
  to: 'Do',
  anyDriver: 'Jakýkoliv řidič',
  driver: 'Řidič',
  vehicle: 'Vozidlo',
  months: 'měsíce',
  month: 'měsíc',
  weeks: 'týdny',
  today: 'Dnes',
  yesterday: 'Včera',
  days: 'Dny',
  everyDay: 'Kazdý den',
  years: 'Roky',
  minute: 'Minuta',
  minutes: 'Minuty',
  minutes2: 'Minut',
  hour: 'Hodina',
  hours: 'Hodiny',
  seconds: 'Sekundy',
  unlimited: 'Neomezený',
  defaultAllDriver: 'Jakýkoliv řidič',
  allOrders: 'Všechny objednávky',
  true: 'Ano',
  false: 'Ne',
  enabled: 'Zapnuto',
  disabled: 'Vypnuto',
  noLimit: 'Žádný limit',
  uploadNewPicture: 'Nahrát novou',
  cropImage: 'Oříznout obrázek',
  crop: 'Potvrdit',
  remove: 'Odstranit',
  mustBeValidEmail: 'Musí být platný email',
  fieldRequired: 'Pole je povinné',
  minCharacters: 'Minimálně 5 znaků',
  minCharacters1: 'Minimálně 1 znak',
  valueMustBeAtLeastOne: 'Musí být číslo větší než 1',
  valueMustBeAtLeastMinus1: 'Musí být číslo větší než -1',
  mustBeBoolean: 'Musí být Ano nebo Ne',
  mustBeNumber: 'Musí být číslo mezi 1 a 20',
  mustBePhoneNumber: 'Telefonní číslo musí být ve formátu +420XXXXXXXXX',
  voiceRecordingOrFromToIsRequired: 'Vyžaduje se buď "Hlasová poznámka" nebo adresa "Od" a "Do"',
  CzechRepublic: 'Česká republika',
  Slovakia: 'Slovensko',
  Poland: 'Polsko',
  Hungary: 'Maďarsko',
  Hungarian: 'Maďarština',
  Slovak: 'Slovenština',
  Polish: 'Polština',
  Czech: 'Čeština',
  setupNotifications: 'Nastavení notifikací',
  addEmail: 'Přidat email',
  enterEmail: 'Zadejte email',
  noEmails: 'E-maily pro přijímání upozornění nejsou nastaveny',
  setupEmails: 'Nastavit e-maily',
  errorMessages: {
    invalidCredentials: 'Neplatný email nebo heslo',
    genericError: 'Došlo k chybě. Statusový kód: {status}',
    socketDisconnectedPleaseRefreshPage: 'Odpojeno od serveru, prosím obnovte stránku',
    notFound: 'Nemohli jsme najít stránku, kterou hledáte.',
    404: 'Stránka nenalezena ⚠️'
  },
  orderStatuses: {
    INCOMING: 'Příchozí',
    CANCELLED: 'Zrušená',
    ANSWERED: 'Odpovězená',
    PROGRESS: 'Probíhající',
    COMPLETED: 'Dokončená',
    ANSWEREDFORWARDED: 'Přesměrovaná',
    UNANSWERED: 'Zmeškaná',
    OWNORDER: 'Vlastní objednávka',
    AWAITINGCUSTOMER: 'Čeká na zákazníka',
    PLANNED: 'Plánovaná',
    PROCESSED: 'Zpracováno',
    CUSTOMERAPPCREATED: 'Vytvořena',
    TIMEDOUT: 'Zmeškaná',
    'OFFLINE-MISSED': 'OFFLINE'
  },
  driverStatuses: {
    free: 'Volný',
    occupied: 'Obsazený',
    onBreak: 'Na přestávce',
    onCall: 'Má hovor'
  },
  login: {
    header: 'Přihlaste se do svého účtu a začněte dobrodružství',
    login: 'Přihlásit se'
  },
  menu: {
    dividers: {
      vehiclesAndDrivers: 'Vozidla a řidiči',
      phones: 'Telefony',
      myCompany: 'Moje společnost',
      app: 'Aplikace'
    },
    tooltip: {
      vehicles: 'Zde můžete vytvořit a upravit vozidla, která budou vaši řidiči používat',
      drivers: 'Zde můžete vytvořit a upravit profily řidičů. Tyto profily budou použity k jejich přihlášení a můžete také nastavit limity na hovory a objednávky pro každého řidiče',
      vehiclePhones: 'Zde musíte nastavit telefonní čísla, která vaši řidiči použijí k přijímání hovorů zákazníků',
      phoneBook: 'Spravujte kontakty zákazníků: ukládejte zákazníky, abyste přiřadili oblíbené řidiče nebo zablokujte zákazníky, abyste jim zabránili volat',
      switchBoard: 'Zobrazit a spravovat hlavní linku taxislužby. Ujistěte se, že všechny taxi telefony jsou přesměrovány sem, aby řidiči mohli přijímat hovory. Nastavte příchozí hovory pro společnost',
      taxiosSentinel: 'Monitorování polohy taxi, stavu STK, emisí a dalších údajů'
    },
    dashboard: 'Dashboard',
    statistics: 'Statistiky',
    orders: 'Objednávky',
    map: 'Mapa',
    vehicles: 'Vozidla',
    drivers: 'Řidiči',
    phonesInVehicles: 'Telefony ve vozidlech',
    phoneBook: 'Telefonní seznam',
    phoneSwitchboard: 'Taxi ústředna',
    invoices: 'Faktury',
    settings: 'Nastavení',
    logout: 'Odhlásit se',
    help: 'Pomoc',
    customerApp: 'Zákaznická Appka',
    taxiosDriver: 'Taxios Driver Appka',
    fareSettings: 'Nastavení tarifů',
    vrpSK: 'VRP (SK)',
    taxiosSentinel: 'Taxios Sentinel',
    notifications: 'Notifikace'
  },
  dashboard: {
    loggedInDrivers: 'Řidiči online',
    viewingDataFor: 'Zobrazení statistik pro:',
    completed: 'dokončené',
    cancelled: 'zrušené',
    total: 'celkově',
    todayCompleted: 'Dnes dokončené',
    todayCancelled: 'Dnes zrušené',
    todayTotal: 'Dnes celkem',
    planned: 'Plánované',
    welcomeBack: 'Vítejte zpět',
    welcomeText: 'Připravili jsme nový panel, který je plný nových funkcí a pomáhá vám lépe spravovat taxislužbu! Užívejte si to a dejte nám vědět, jak se vám daří',
    analyticsMonthlyView: {
      header: 'Analýza počtu objednávek: Roční a měsíční přehled'
    }
  },
  orders: {
    header: 'Objednávky',
    openOrderDetailModal: 'Detail objednávky',
    displayOnlyPlannedOrders: 'Zobrazit pouze plánované objednávky',
    saveCustomer: 'Uložit zákazníka',
    editCustomer: 'Upravit zákazníka',
    cancelPlannedOrder: 'Zrušit plánovanou objednávku',
    cancelPlannedOrderText: 'Opravdu chcete zrušit tuto plánovanou objednávku?',
    addOrder: 'Přidat obj.',
    search: {
      customerPhoneNumber: 'Zákazník (číslo nebo jméno)',
      driverName: 'Jméno řidiče',
      timeFrom: 'Od',
      timeTo: 'Do',
      orderStatus: 'Stav objednávky',
      timeRange: 'Datum / Čas',
      range: {
        today: 'Dnes',
        yesterday: 'Včera',
        last7: 'Posledních 7 dní',
        thisMonth: 'Tento měsíc',
        lastMonth: 'Minulý měsíc',
        custom: 'Vlastní rozsah'
      }
    },
    itemsPerPage: 'Položek na stránku',
    orderId: 'ID objednávky',
    orderStarted: 'Začátek',
    orderCompleted: 'Dokončeno',
    length: 'Délka',
    orderStatus: 'Stav',
    driver: 'Řidič',
    customer: 'Zákazník',
    options: 'Možnosti',
    recordsFound: 'nalezených záznamů',
    callRecording: 'Nahrávání hovoru',
    ownOrder: 'Vlastní objednávka',
    from: 'Místo vyzvednutí',
    to: 'Cílová destinace',
    modal: {
      header: 'Detail objednávky',
      order: 'Objednávka',
      incomingCall: 'Příchozí hovor',
      tryingDifferentDriver: 'Zkouším jiného řidiče',
      unansweredCall: 'Zmeškaný hovor',
      orderCancelled: 'Obj. Zrušena',
      callAnswered: 'Hovor Odpovězený',
      forwardedOrder: 'Obj. Přesměrována',
      orderInProgress: 'Obj. Probíhá',
      orderCompleted: 'Dokončená',
      didNotAnswered: 'Nezvedl telefon',
      ownOrder: 'Vlastní obj.',
      customer: 'Zákazník',
      driver: 'Řidič',
      toDriver: 'K řidiči',
      fromDriver: 'Od řidiče',
      plannedTime: 'Naplánováno na',
      sentToDriver: 'Odesláno na řidiče',
      plannedOrder: 'Obj. Plánovaná',
      recovered: 'Obj. Obnovena',
      adminCreated: 'Vytvořena adminem',
      dispatcherCreated: 'Vytvořena dispečerem',
      reminder: 'Připomenutí'
    },
    manualOrderModal: {
      header: 'Nová objednávka',
      customerPhoneNumber: 'Telefonní číslo zákazníka',
      plannedOrderFlag: 'Plánovaná objednávka',
      from: 'Od',
      to: 'Do',
      driver: 'Řidič',
      note: 'Poznámka',
      plannedToTimeStamp: 'Plánované časové razítko',
      remindBeforeStartTimeInMinutes: 'Připomenout před začátkem v minutách',
      startsNow: 'objednávka začíná nyní',
      startsLater: 'objednávka začíná později',
      recording: 'Hlasová poznámka',
      record: 'Nahrát',
      delete: 'Smazat',
      save: 'Uložit',
      stop: 'Stop',
      recordingAdded: 'Hlasová poznámka přidána'
    }
  },
  statistics: {
    labelDateRange: 'Rozsah dat na zobrazení statistik',
    completed: 'Dokončené objednávky',
    cancelled: 'Zrušené objednávky',
    total: 'Celkově',
    averageOrderTime: 'Průměrný čas objednávky',
    dailyOrdersHeader: 'Analýza počtu objednávek: Denní přehled zrušené vs dokončené',
    completedOrderPerDriverHeader: 'Analýza dokončených objednávek na řidiče',
    completedOrderPerDriverVsWorkedHoursHeader: 'Dokončené objednávky na řidiče vs odpracované hodiny',
    workedHoursPerDriver: 'Odpracované hodiny na řidiče',
    someStatsNotVisibleMdAndDown: 'Některé statistiky nejsou viditelné na mobilních zařízeních.',
    worstVsBestDriverHeader: 'Nejhorší vs Nejlepší řidič dneška'
  },
  taxiDrivers: {
    header: 'Taxi řidiči',
    picture: 'Obrázek',
    name: 'Jméno',
    email: 'Email',
    usingPhone: 'Používá telefon',
    loggedIn: 'Přihlášen',
    lastLogin: 'Poslední přihlášení',
    vehicle: 'Používá vozidlo',
    maxOrders: 'Maximální počet objednávek',
    allowForwards: 'Povolit přesměrování',
    disableCalls: 'Zakázat hovory',
    password: 'Heslo',
    options: 'Možnosti',
    addDriver: 'Přidat řidiče',
    currentlyHave: 'Máte',
    drivers: 'řidičů',
    maxNumOfConcurrentOrdersDescription: 'Maximální počet současných objednávek, které řidič může mít najednou',
    allowForwardsFromOthersWhenMaxOrdersReachedDescription: 'Povolit ostatním řidičům přesměrovat objednávky na řidiče, který dosáhl maximálního počtu objednávek (pole Maximální počet objednávek musí být aktivováno)',
    disableCallsDescription: 'Řidič bude mít příchozí hovory zakázány, ostatní mohou stále přesměrovat objednávku na něj a bude moci volat zákazníkům z jeho aktivní objednávky',
    modal: {
      titleAdd: 'Přidat řidiče',
      titleEdit: 'Upravit řidiče',
      removeDriver: 'Odstranit řidiče'
    }
  },
  taxiVehicles: {
    header: 'Taxi vozidla',
    addVehicle: 'Přidat vozidlo',
    name: 'Jméno',
    plate: 'SPZ',
    model: 'Model',
    manufacturer: 'Výrobce',
    year: 'Rok',
    color: 'Barva',
    fuel: 'Palivo',
    currentDriver: 'Současný řidič',
    seats: 'Sedadla',
    options: 'Možnosti',
    currentlyHave: 'Máte',
    vehicles: 'vozidla',
    removeDriverFromACarDescription: 'Tímto se řidič odhlásí a vozidlo bude volné!',
    youHaveRemovedDriverFromVehicle: 'Řidič byl odpojen od vozidla',
    vehicleKm: 'Kilometry',
    vehicleKm2: 'Najeté kilometry',
    lastOilChange: 'Olej vyměněn',
    vignette: 'Dálniční známka',
    mot: 'STK/EK',
    insurance: 'PZP',
    motStatus: 'STK',
    emissionsStatus: 'EM',
    lastOilChange2: 'Poslední výměna oleje',
    oilChangeIntervals: 'Intervaly výměny oleje',
    newOilChange: 'Výměna oleje',
    howMuchKmOilChange: 'Při kolika km byla vyměněna olej',
    dateWhenOilChanged: 'Datum výměny oleje',
    vehicleData: 'Obecné údaje o vozidle',
    vehicleHistory: 'Historie polohy vozidla',
    driverHistory: 'Historie řidičů',
    sentinelNotificationEmails: 'Emaily pro odeslání upozornění',
    sentinelNotificationEmailsDescription: 'Zadejte e-mailové adresy pro zasílání upozornění o vypršení platnosti STK, emisí, dálniční známky a PZP (pojištění).',
    validFrom: 'Platné od',
    validTill: 'Platné do',
    lastCheck: 'Poslední kontrola',
    timeDifference: 'Časový rozdíl mezi "od" a "do" může být maximálně 24 hodin',
    noVignette: 'Žádná dálniční známka. Synchronizace s vládními weby probíhá každých 24 hodin, ale někdy může trvat až 5 dní.',
    motCheck: 'Kontrola STK probíhá každých 24 hodin, ale někdy může trvat až 5 dní.',
    modal: {
      removeVehicle: 'Odstranit vozidlo',
      titleEdit: 'Upravit vozidlo',
      titleAdd: 'Přidat vozidlo',
      driverLogout: 'Odhlásit řidiče a uvolnit vozidlo?',
      willBeLoggedOut: 'bude odhlášen',
      willBeFreed: 'bude uvolněno'
    }
  },
  taxiPhones: {
    header: 'Telefony ve vozidlech',
    addPhone: 'Přidat telefon',
    name: 'Jméno',
    phoneNumber: 'Telefonní číslo',
    registered: 'Registrované',
    note: 'Poznámka',
    phoneId: 'ID telefonu',
    version: 'Verze',
    options: 'Možnosti',
    modal: {
      titleAdd: 'Přidat telefonní číslo',
      titleEdit: 'Upravit telefonní číslo',
      removePhoneNumbers: 'Odstranit telefonní číslo'
    }
  },
  taxiPhoneDirectory: {
    header: 'Telefonní seznam',
    addPhone: 'Uložit nového zákazníka',
    name: 'Jméno',
    phoneNumber: 'Telefonní číslo',
    favoriteDriver: 'Oblíbený řidič',
    note: 'Poznámka',
    blocked: 'Blokováno',
    options: 'Možnosti',
    modal: {
      titleAdd: 'Uložit nového zákazníka',
      titleEdit: 'Upravit uloženého zákazníka',
      doesNotHaveFavoriteDriver: 'Nemá oblíbeného řidiče'
    }
  },
  taxiSwitchBoard: {
    header: 'Taxi ústředna',
    headerStatistics: 'Statistiky taxi ústředny',
    taxiNumber: 'Telefonní číslo',
    phoneNumberDescription: 'Telefonní číslo, na které byste měli přesměrovat všechny hovory přicházející do vaší společnosti',
    callMaxTimeout: 'Maximální čas zvonění',
    fallBackNumber: 'Záložní telefonní číslo',
    required: 'Pole je povinné',
    callMaxTimeoutError: 'Časový limit musí být mezi 10 a 20 sekundami',
    fallBackNumberError: 'Číslo musí začínat na +'
  },
  taxiInvoices: {
    header: 'Moje faktury',
    invoiceName: 'Název faktury',
    invoiceNumber: 'Číslo faktury',
    periodMonth: 'Měsíc období',
    issueDate: 'Datum vystavení',
    paymentDue: 'Splatnost',
    paid: 'Datum zaplacení',
    totalWithoutTax: 'Celková částka bez daně',
    taxAmount: 'Výše daně',
    total: 'Celkem',
    options: 'Možnosti',
    unpaid: 'Nezaplaceno',
    info: 'Faktury jsou zasílány na vaši emailovou adresu (uvedenou v',
    settings: 'nastaveních',
    inTabInvoiceDetails: 'v záložce fakturační údaje)',
    everyMonth: 'každého 15. dne v měsíci a musí být uhrazeny do 15 dní (zpravidla 1. dne v měsíci, za který je faktura vystavena)'
  },
  taxiosCustomerApp: {
    header: 'Aplikace Taxios pro zákazníky',
    pleaseContactUs1: 'Pokud chcete mít vlastní aplikaci (samozřejmě s vaším logem a barvami :) a dostupnou na Apple a Androidu), kde si vaši zákazníci mohou objednat taxi bez toho, aby vám volali, kontaktujte nás prostřednictvím emailu ',
    pleaseContactUs2: ' a my vám pomůžeme to uskutečnit.'
  },
  taxiosDriverApp: {
    header: 'Nastavení aplikace řidiče Taxios',
    minLoggedDrivers: 'Minimální počet přihlášených řidičů',
    maxDriversOnBreak: 'Maximální počet řidičů na přestávce',
    maxDriversDisabledCalls: 'Maximální počet řidičů s deaktivovanými hovory',
    showEachOtherStatistics: 'Zobrazit statistiky ostatních',
    showEachOtherStatisticsDescription: 'Pokud je vybráno "Ano", řidiči mohou vidět statistiky ostatních řidičů v postranním menu pod "Naši řidiči". Pokud je vybráno "Ne", statistiky pro ně nebudou viditelné.',
    voiceChatButtonMode: 'Režim tlačítka hlasového chatu',
    voiceChatButtonModeDescription: 'Když je vybráno "PTT", řidič musí stisknout a držet tlačítko, když chce mluvit, a uvolnit ho, když chce zkončit. Když je vybráno "TOGGLE", jedno stisknutí aktivuje hlasový chat na nastavenou dobu a poté se automaticky ukončí.',
    voiceChatToggleTimeLimiter: 'Časový limit hlasového chatu',
    voiceChatToggleTimeLimiterDescription: 'Nastaví dobu (v sekundách), po kterou zůstane hlasový chat aktivní v režimu TOGGLE po stisknutí tlačítka.',
    maxAllowedCancels: 'Maximální počet povolených zrušení',
    maxAllowedCancelsDescription: 'Omezuje, kolikrát může řidič zrušit objednávku a obnovit své první místo ve frontě. Po dosažení limitu zůstane na konci fronty a musí čekat na další kolo.',
    maxAllowedForwards: 'Maximální počet povolených přesměrování',
    maxAllowedForwardsDescription: 'Omezuje, kolikrát může řidič předat objednávku kolegovi a obnovit své první místo ve frontě. Po dosažení limitu zůstane na konci fronty a musí čekat na další kolo.',
    maxAllowedMissedCalls: 'Maximální počet povolených zmeškaných hovorů',
    maxAllowedMissedCallsDescription: 'Omezuje, kolik hovorů může řidič zmeškat, než bude přesunut na konec fronty. Po dosažení limitu musí řidič čekat na další kolo.',
    maxAllowedTransactionsTogether: 'Maximální počet povolených transakcí najednou',
    maxAllowedLengthOfOrderInSecondsToResetQueue: 'Maximální délka objednávky (v sekundách) na resetování fronty',
    maxAllowedLengthOfOrderInSecondsToResetQueueDescription: 'Nastaví časový limit (v sekundách), během kterého může řidič zrušit objednávku a být vrácen na své původní první místo ve frontě. Pokud řidič pracuje na objednávce déle než tento limit, zrušení objednávky neobnoví jeho pozici ve frontě.',
    pushToTalk: 'Stiskněte a mluvte',
    toggleToTalk: 'Stiskněte jednou, pak mluvte až do vypršení časového limitu',
    driversManagement: 'Nastavení řidičů',
    voiceChatSettings: 'Nastavení hlasového chatu',
    phoneQueueSettings: 'Nastavení telefonní fronty',
    lastUpdated: 'Naposledy změněno',
    showCustomerNumberToTheDriver: 'Zobrazit číslo zákazníka řidiči',
    showCustomerNumberToTheDriverDescription: 'Pokud je vybráno "Ano", řidiči mohou vidět číslo zákazníka v aplikaci. Pokud je vybráno "Ne", uvidí pouze tlačítko pro zpětné volání.',
    anybodyCanEditPlannedOrder: 'Každý řidič může upravit plánované objednávky',
    anybodyCanEditPlannedOrderDescription: 'Pokud je vybráno "Ano", každý řidič může upravit jakoukoli plánovanou objednávku. Pokud je vybráno "Ne", může objednávku upravit pouze administrátor nebo řidič, který ji vytvořil.',
    allowDriverToSaveCustomer: 'Povolit řidiči uložit zákazníka',
    allowDriverToSaveCustomerDescription: 'Pokud je vybrána možnost "Ano", řidiči budou moci ukládat telefonní čísla zákazníků do vašeho telefonního seznamu.',
    allowDriverToBlockCustomer: 'Povolit řidiči zablokovat zákazníka',
    allowDriverToBlockCustomerDescription: 'Pokud je vybrána možnost "Ano", řidiči budou mít možnost zablokovat telefonní číslo zákazníka při ukládání do vašeho telefonního seznamu. Pokud bude zákazník zablokován, při příštím volání se nedovolá na vaši taxislužbu.'
  },
  settings: {
    admins: 'Administrátoři',
    companyDetails: 'Detaily společnosti',
    invoiceDetails: 'Fakturační údaje',
    myInvoices: 'Moje faktury',
    name: 'Jméno taxislužby',
    companyName: 'Název společnosti',
    companyAddress: 'Adresa',
    companyAddress2: 'Adresa 2',
    companyAddress3: 'Adresa 3',
    companyCity: 'Město',
    companyPostCode: 'PSČ',
    companyEmail: 'Email',
    companyPhone: 'Telefon',
    companyRegisteredFrom: 'Registrováno od',
    companyCountry: 'Země',
    companyIdentificationNumber: 'Identifikační číslo',
    companyTaxNumber: 'DIČ',
    invoiceLanguage: 'Jazyk faktury',
    password: 'Heslo',
    role: 'Role',
    email: 'Email',
    options: 'Možnosti',
    admin: 'Admin',
    dispatchAgent: 'Dispečer',
    lastLoggedIn: 'Poslední přihlášení',
    addAdmin: 'Přidat nového admina',
    adminName: 'Jméno administrátora',
    modal: {
      titleAdd: 'Přidat administrátora',
      titleEdit: 'Upravit administrátora',
      removeAdmin: 'Odstranit administrátora'
    }
  },
  taxiosVCR: {
    header: 'Integrace VRP 2',
    subheader: '(VRP Slovensko)',
    enableVRP: 'Povolit integraci VRP do aplikace Taxios Driver?'
  },
  fareSettings: {
    header: 'Nastavení tarifů'
  },
  help: {
    header: 'Pomoc',
    status: 'Status',
    socketStatus: 'Připojení k serveru ',
    radioSocketStatus: 'Připojení k vysílačkovému serveru '
  },
  logout: {
    header: 'Odhlásit se',
    areYouSure: 'Opravdu se chcete odhlásit?',
    logoutButton: 'Odhlásit se'
  },
  taxiosNotifications: {
    header: 'Nastavení upozornění',
    notificationsEmails: 'Vaše e-maily, kam zasíláme upozornění',
    drivers: 'Řidiči',
    notifyOnDriverLogin: 'Upozornit na přihlášení řidiče',
    notifyOnDriverLogout: 'Upozornit na odhlášení řidiče',
    notifyWhenDriverOnBreak: 'Upozornit, když řidič zahájí přestávku',
    notifyWhenDriverEndsBreak: 'Upozornit, když řidič ukončí přestávku',
    notificationsEmailsForDrivers: 'E-maily pro upozornění o řidičích',
    orders: 'Objednávky',
    notifyOnMissedOrderCall: 'Upozornit, když řidič zmešká hovor',
    notifyOnMissedPlannedOrder: 'Upozornit, když řidič nepodnikne žádnou akci na plánovanou objednávku',
    notifyOnPlannedOrderCreated: 'Upozornit, když řidič vytvoří plánovanou objednávku',
    notifyOnCompletedOrder: 'Upozornit, když řidič dokončí objednávku',
    taxiosSentinel: 'Taxios Sentinel',
    taxiosSentinelNotifications: 'Upozornění Taxios Sentinel',
    taxiosSentinelNotificationsDescription: `
      Dostávejte včasná upozornění od Taxios Sentinel, abyste měli údržbu vozidla pod kontrolou. 
      <br /><br />
      <strong>Výmena oleje:</strong> Upozornění budou zasílána v následujících intervalech, s odpočtem do výměny oleje:
      <ul>
        <li>1. upozornění při zbývajících 2000 km</li>
        <li>2. upozornění při zbývajících 1000 km</li>
        <li>3. upozornění při zbývajících 100 km</li>
        <li>Denní připomínky budou pokračovat, dokud nebude výměna oleje potvrzena v Taxios Sentinel</li>
      </ul>
      <br />
      <strong>STK/Emise:</strong> Upozornění budou zasílána:
      <ul>
        <li>1. upozornění 2 měsíce před</li>
        <li>2. upozornění 1 měsíc před</li>
        <li>3. upozornění 1 týden před</li>
      </ul>
    `
  }
}
