export default {
  title: 'Taxios Admin',
  entries: 'záznamy',
  email: 'Email',
  emails: 'Emaily',
  password: 'Heslo',
  select: 'Vybrať',
  cancel: 'Zrušiť',
  close: 'Zatvoriť',
  activate: 'Aktivovať',
  deactivate: 'Deaktivovať',
  edit: 'Upraviť',
  save: 'Uložiť',
  submit: 'Odoslať',
  confirm: 'Potvrdiť',
  selectOne: 'Vybrať',
  saveChanges: 'Uložiť zmeny',
  changeOil: 'Vymeniť olej',
  startTyping: 'Začnite písať',
  download: 'Stiahnuť',
  loadData: 'Načítať údaje',
  required: 'Nemôže byť prázdne',
  search: 'Hľadať',
  from: 'Od',
  to: 'Do',
  anyDriver: 'Akýkoľvek vodič',
  driver: 'Vodič',
  vehicle: 'Vozidlo',
  months: 'mesiace',
  month: 'mesiac',
  weeks: 'týždne',
  today: 'Dnes',
  yesterday: 'Včera',
  days: 'Dni',
  everyDay: 'Kazdý deň',
  years: 'Roky',
  minute: 'Minúta',
  minutes: 'Minúty',
  minutes2: 'Minút',
  hour: 'Hodina',
  hours: 'Hodiny',
  seconds: 'Sekundy',
  unlimited: 'Neobmedzený',
  defaultAllDriver: 'Akýkoľvek vodič',
  allOrders: 'Všetky objednávky',
  true: 'Áno',
  false: 'Nie',
  enabled: 'Zapnuté',
  disabled: 'Vypnuté',
  noLimit: 'Žiadny limit',
  uploadNewPicture: 'Nahrať novú',
  cropImage: 'Orezať obrázok',
  crop: 'Potvrdiť',
  remove: 'Odstrániť',
  mustBeValidEmail: 'Musí byť platný email',
  fieldRequired: 'Pole je povinné',
  minCharacters: 'Minimálne 5 znakov',
  minCharacters1: 'Minimálne 1 znak',
  valueMustBeAtLeastOne: 'Musí byť číslo väčšie ako 1',
  valueMustBeAtLeastMinus1: 'Musí byť číslo vjacej ako -1',
  mustBeBoolean: 'Musí byť Ano alebo Nie',
  mustBeNumber: 'Musí byť číslo medzi 1 a 20',
  mustBePhoneNumber: 'Telefónne číslo musí byť vo formáte +421XXXXXXXXX',
  voiceRecordingOrFromToIsRequired: 'Vyžaduje sa buď "Hovorená poznámka" alebo adresa "Od" a "Do"',
  radioSettings: 'Nastavenia vysielačky',
  speak: 'Hovoriť',
  speaking: 'Hovorím',
  radioMode: 'Rádiový režim',
  toggleInterval: 'Interval zapnutia',
  browser_not_supported: 'Prehliadač nie je podporovaný - použite prosím prehliadače Google Chrome alebo Chromium na PC/Mac',
  CzechRepublic: 'Česká republika',
  Slovakia: 'Slovensko',
  Poland: 'Poľsko',
  Hungary: 'Maďarsko',
  Hungarian: 'Maďarčina',
  Slovak: 'Slovenčina',
  Polish: 'Poľština',
  Czech: 'Čeština',
  setupNotifications: 'Nastavenie notifikácii',
  addEmail: 'Pridať email',
  enterEmail: 'Zadajte email',
  setupEmails: 'Nastaviť e-maily',
  noEmails: 'E-maily na prijímanie upozornení nie sú nastavené',
  errorMessages: {
    invalidCredentials: 'Neplatný email alebo heslo',
    genericError: 'Došlo k chybe. Statusový kód: {status}',
    socketDisconnectedPleaseRefreshPage: 'Odpojené od servera, prosím obnovte stránku',
    notFound: 'Nemohli sme nájsť stránku, ktorú hľadáte.',
    404: 'Stránka nenájdená ⚠️'
  },
  orderStatuses: {
    INCOMING: 'Prichádzajúcá',
    CANCELLED: 'Zrušená',
    ANSWERED: 'Odpovedaná',
    PROGRESS: 'Prebiehajúcá',
    COMPLETED: 'Dokončená',
    ANSWEREDFORWARDED: 'Presmerovaná',
    UNANSWERED: 'Zmeškaná',
    OWNORDER: 'Vlastná objednávka',
    AWAITINGCUSTOMER: 'Čaká na zákazníka',
    PLANNED: 'Plánovaná',
    PROCESSED: 'Spracované',
    CUSTOMERAPPCREATED: 'Vytvorená',
    TIMEDOUT: 'Zmeškaná',
    'OFFLINE-MISSED': 'OFFLINE'
  },
  driverStatuses: {
    free: 'Voľný',
    occupied: 'Obsadený',
    onBreak: 'Na prestávke',
    onCall: 'Má hovor'
  },
  login: {
    header: 'Prihláste sa do svojho účtu a začnite dobrodružstvo',
    login: 'Prihlásiť sa'
  },
  menu: {
    dividers: {
      vehiclesAndDrivers: 'Vozidlá a vodiči',
      phones: 'Telefóny',
      myCompany: 'Moja spoločnosť',
      app: 'Aplikácia'
    },
    tooltip: {
      vehicles: 'Tu môžete vytvoriť a upraviť vozidlá, ktoré budú vaši vodiči používať',
      drivers: 'Tu môžete vytvoriť a upraviť profily vodičov. Tieto profily budú použité na ich prihlásenie a môžete tiež nastaviť limity na hovory a objednávky pre každého vodiča',
      vehiclePhones: 'Tu musíte nastaviť telefónne čísla, ktoré vaši vodiči použijú na prijímanie hovorov zákazníkov',
      phoneBook: 'Spravujte kontakty zákazníkov: ukladajte zákazníkov, aby ste priradili obľúbených vodičov alebo zablokujte zákazníkov, aby ste im zabránili volať',
      switchBoard: 'Zobraziť a spravovať hlavnú linku taxislužby. Uistite sa, že všetky taxi telefóny sú presmerované sem, aby vodiči mohli prijímať hovory. Nastavte prichádzajúce hovory pre spoločnosť',
      taxiosSentinel: 'Monitorovanie polohy taxíka, stavu STK, emisií a ďalších údajov.'
    },
    dashboard: 'Dashboard',
    statistics: 'Štatistiky',
    orders: 'Objednávky',
    map: 'Mapa',
    vehicles: 'Vozidlá',
    drivers: 'Vodiči',
    phonesInVehicles: 'Telefóny vo vozidlách',
    phoneBook: 'Telefónny zoznam',
    phoneSwitchboard: 'Taxi ústredňa',
    invoices: 'Faktúry',
    settings: 'Nastavenia',
    logout: 'Odhlásiť sa',
    help: 'Pomoc',
    customerApp: 'Zákaznícká Appka',
    taxiosDriver: 'Taxios Driver Appka',
    fareSettings: 'Nastavenia taríf',
    vrpSK: 'VRP (SK)',
    taxiosSentinel: 'Taxios Sentinel',
    notifications: 'Notifikácie'
  },
  dashboard: {
    loggedInDrivers: 'Vodiči online',
    viewingDataFor: 'Zobrazenie štatistík pre:',
    completed: 'dokončené',
    cancelled: 'zrušené',
    total: 'celkovo',
    todayCompleted: 'Dnes dokončené',
    todayCancelled: 'Dnes zrušené',
    todayTotal: 'Dnes celkom',
    planned: 'Plánované',
    welcomeBack: 'Vitajte späť',
    welcomeText: 'Pripravili sme nový panel, ktorý je plný nových funkcií a pomáha vám lepšie spravovať taxislužbu! Užite si to a dajte nám vedieť, ako sa vám darí',
    analyticsMonthlyView: {
      header: 'Analýza počtu objednávok: Ročný a mesačný prehľad'
    }
  },
  orders: {
    header: 'Objednávky',
    openOrderDetailModal: 'Detail objednávky',
    displayOnlyPlannedOrders: 'Zobraziť iba plánované objednávky',
    saveCustomer: 'Uložiť zákazníka',
    editCustomer: 'Upraviť zákazníka',
    cancelPlannedOrder: 'Zrušiť plánovanú objednávku',
    cancelPlannedOrderText: 'Naozaj chcete zrušiť túto plánovanú objednávku?',
    addOrder: 'Pridať obj.',
    search: {
      customerPhoneNumber: 'Zákazník (číslo alebo meno)',
      driverName: 'Meno vodiča',
      timeFrom: 'Od',
      timeTo: 'Do',
      orderStatus: 'Stav objednávky',
      timeRange: 'Dátum / Čas',
      range: {
        today: 'Dnes',
        yesterday: 'Včera',
        last7: 'Posledných 7 dní',
        thisMonth: 'Tento mesiac',
        lastMonth: 'Minulý mesiac',
        custom: 'Vlastný rozsah'
      }
    },
    itemsPerPage: 'Položiek na stránku',
    orderId: 'ID objednávky',
    orderStarted: 'Začiatok',
    orderCompleted: 'Dokončené',
    length: 'Dĺžka',
    orderStatus: 'Stav',
    driver: 'Vodič',
    customer: 'Zákazník',
    options: 'Možnosti',
    recordsFound: 'nájdených záznamov',
    callRecording: 'Nahrávka',
    ownOrder: 'Vlastná objednávka',
    from: 'Miesto vyzdvihnutia',
    to: 'Cieľová destinácia',
    modal: {
      header: 'Detail objednávky',
      order: 'Objednávka',
      incomingCall: 'Prichádzajúci hovor',
      tryingDifferentDriver: 'Skúšam iného vodiča',
      unansweredCall: 'Zmeškaný hovor',
      orderCancelled: 'Obj. Zrušená',
      callAnswered: 'Hovor Odpovedaný',
      forwardedOrder: 'Obj. Pesmerovaná',
      orderInProgress: 'Obj. Prebieha',
      orderCompleted: 'Dokončená',
      didNotAnswered: 'Nezdvhol telefon',
      ownOrder: 'Vlastná obj.',
      customer: 'Zákazník',
      driver: 'Vodič',
      toDriver: 'Ku vodičovi',
      fromDriver: 'Od vodiča',
      plannedTime: 'Naplánovaná na',
      sentToDriver: 'Odoslané na vodiča',
      plannedOrder: 'Obj. Naplánovana',
      recovered: 'Obj. Obnovena',
      adminCreated: 'Vytvorená adminom',
      dispatcherCreated: 'Vytvorená dispečerom',
      reminder: 'Upozornenie'
    },
    manualOrderModal: {
      header: 'Nová objednávka',
      customerPhoneNumber: 'Telefónne číslo zákazníka',
      plannedOrderFlag: 'Plánovaná objednávka',
      from: 'Od',
      to: 'Do',
      driver: 'Vodič',
      note: 'Poznámka',
      plannedToTimeStamp: 'Plánované časové razítko',
      remindBeforeStartTimeInMinutes: 'Pripomenúť pred začiatkom v minútach',
      startsNow: 'objednávka začína teraz',
      startsLater: 'objednávka začína neskôr',
      recording: 'Hovorená poznámka',
      record: 'Nahrať',
      delete: 'Smazať',
      save: 'Uložiť',
      stop: 'Stop',
      recordingAdded: 'Hovorená poznámka pridaná'
    }
  },
  statistics: {
    labelDateRange: 'Rozsah dátumov na zobrazenie štatistík',
    completed: 'Dokončené objednávky',
    cancelled: 'Zrušené objednávky',
    total: 'Celkovo',
    averageOrderTime: 'Priemerný čas objednávky',
    dailyOrdersHeader: 'Analýza počtu objednávok: Denný prehľad zrušené vs dokončené',
    completedOrderPerDriverHeader: 'Analýza dokončených objednávok na vodiča',
    completedOrderPerDriverVsWorkedHoursHeader: 'Dokončené objednávky na vodiča vs odpracované hodiny',
    workedHoursPerDriver: 'Odpracované hodiny na vodiča',
    someStatsNotVisibleMdAndDown: 'Niektoré štatistiky nie sú viditeľné na mobilných zariadeniach.',
    worstVsBestDriverHeader: 'Najhorší vs Najlepší vodič dneška'
  },
  taxiDrivers: {
    header: 'Taxi vodiči',
    picture: 'Obrázok',
    name: 'Meno',
    email: 'Email',
    usingPhone: 'Používa telefón',
    loggedIn: 'Prihlásený',
    lastLogin: 'Posledné prihlásenie',
    vehicle: 'Používa vozidlo',
    maxOrders: 'Maximálny počet objednávok',
    allowForwards: 'Povoliť presmerovania',
    disableCalls: 'Zakázať hovory',
    password: 'Heslo',
    options: 'Možnosti',
    addDriver: 'Pridať vodiča',
    currentlyHave: 'Máte',
    drivers: 'vodičov',
    maxNumOfConcurrentOrdersDescription: 'Maximálny počet súčasných objednávok, ktoré vodič môže mať naraz',
    allowForwardsFromOthersWhenMaxOrdersReachedDescription: 'Povoliť ostatným vodičom presmerovať objednávky na vodiča, ktorý dosiahol maximálny počet objednávok (pole Maximálny počet objednávok musí byť aktivované)',
    disableCallsDescription: 'Vodič bude mať prichádzajúce hovory zakázané, ostatní môžu stále presmerovať objednávku na neho a bude môcť volať zákazníkom z jeho aktívnej objednávky',
    modal: {
      titleAdd: 'Pridať vodiča',
      titleEdit: 'Upraviť vodiča',
      removeDriver: 'Odstrániť vodiča'
    }
  },
  taxiVehicles: {
    header: 'Taxi vozidlá',
    addVehicle: 'Pridať vozidlo',
    name: 'Meno',
    plate: 'ŠPZ',
    model: 'Model',
    manufacturer: 'Výrobca',
    year: 'Rok',
    color: 'Farba',
    fuel: 'Palivo',
    currentDriver: 'Súčasný vodič',
    seats: 'Sedadlá',
    options: 'Možnosti',
    currentlyHave: 'Máte',
    vehicles: 'vozidlá',
    removeDriverFromACarDescription: 'Týmto sa vodič odhlási a vozidlo bude voľné!',
    youHaveRemovedDriverFromVehicle: 'Vodič bol odpojený od vozidla',
    vehicleKm: 'Kilometre',
    vehicleKm2: 'Najazdené kilometre',
    lastOilChange: 'Olej vymenený',
    vignette: 'Diaľničná známka',
    mot: 'STK/EK',
    insurance: 'PZP',
    motStatus: 'STK',
    emissionsStatus: 'EK',
    lastOilChange2: 'Posledná výmena oleja',
    oilChangeIntervals: 'Intervaly výmeny oleja',
    newOilChange: 'Výmena oleja',
    howMuchKmOilChange: 'Pri koľkých km bol vymenený olej',
    dateWhenOilChanged: 'Dátum výmeny oleja',
    vehicleData: 'Vozidlo',
    vehicleHistory: 'História polohy vozidla',
    driverHistory: 'História vodičov',
    sentinelNotificationEmails: 'E-mailové upozornenia Sentinel',
    sentinelNotificationEmailsDescription: 'Zadajte e-mailové adresy, aby ste dostávali upozornenia o vypršaní STK, emisnej kontroly, diaľničnej známky a PZP (poistenie).',
    validFrom: 'Platné od',
    validTill: 'Platné do',
    lastCheck: 'Posledná kontrola',
    timeDifference: 'Časový rozdiel medzi "od" a "do" môže byť maximálne 24 hodin',
    noVignette: 'Žiadna diaľničná známka. Synchronizácia s vládnymi webmi prebieha každých 24 hodín, ale niekedy môže trvať až 5 dní.',
    motCheck: 'Kontrola STK prebieha každých 24 hodín, ale niekedy môže trvať až 5 dní.',
    modal: {
      removeVehicle: 'Odstrániť vozidlo',
      titleEdit: 'Upraviť vozidlo',
      titleAdd: 'Pridať vozidlo',
      driverLogout: 'Odhlásiť vodiča a uvoľniť vozidlo?',
      willBeLoggedOut: 'bude odhlásený',
      willBeFreed: 'bude uvoľnené'
    }
  },
  taxiPhones: {
    header: 'Telefóny vo vozidlách',
    addPhone: 'Pridať telefón',
    name: 'Meno',
    phoneNumber: 'Telefónne číslo',
    registered: 'Registrované',
    note: 'Poznámka',
    phoneId: 'ID telefónu',
    version: 'Verzia',
    options: 'Možnosti',
    modal: {
      titleAdd: 'Pridať telefónne číslo',
      titleEdit: 'Upraviť telefónne číslo',
      removePhoneNumbers: 'Odstrániť telefónne číslo'
    }
  },
  taxiPhoneDirectory: {
    header: 'Telefónny zoznam',
    addPhone: 'Uložiť nového zákazníka',
    name: 'Meno',
    phoneNumber: 'Telefónne číslo',
    favoriteDriver: 'Obľúbený vodič',
    note: 'Poznámka',
    blocked: 'Blokované',
    options: 'Možnosti',
    modal: {
      titleAdd: 'Uložiť nového zákazníka',
      titleEdit: 'Upraviť uloženého zákazníka',
      doesNotHaveFavoriteDriver: 'Nemá obľúbeného vodiča'
    }
  },
  taxiSwitchBoard: {
    header: 'Taxi ústredňa',
    headerStatistics: 'Štatistiky taxi ústredne',
    taxiNumber: 'Telefónne číslo',
    phoneNumberDescription: 'Telefónne číslo, na ktoré by ste mali presmerovať všetky hovory prichádzajúce do vašej spoločnosti',
    callMaxTimeout: 'Maximálny čas zvonenia',
    fallBackNumber: 'Záložné telefónne číslo',
    required: 'Pole je povinné',
    callMaxTimeoutError: 'Časový limit musí byť medzi 10 a 20 sekundami',
    fallBackNumberError: 'Číslo musí začínať na +'
  },
  taxiInvoices: {
    header: 'Moje faktúry',
    invoiceName: 'Názov faktúry',
    invoiceNumber: 'Číslo faktúry',
    periodMonth: 'Mesiac obdobia',
    issueDate: 'Dátum vystavenia',
    paymentDue: 'Splatnosť',
    paid: 'Dátum zaplatenia',
    totalWithoutTax: 'Celková suma bez dane',
    taxAmount: 'Výška dane',
    total: 'Celkom',
    options: 'Možnosti',
    unpaid: 'Nezaplatené',
    info: 'Faktúry sú zasielané na vašu emailovú adresu (uvedenú v',
    settings: 'nastaveniach',
    inTabInvoiceDetails: 'v záložke fakturačné údaje)',
    everyMonth: 'každého 15. dňa v mesiaci a musia byť uhradené do 15 dní (zvyčajne 1. deň v mesiaci, za ktorý je faktúra vystavená)'
  },
  taxiosCustomerApp: {
    header: 'Aplikácia Taxios pre zákazníkov',
    pleaseContactUs1: 'Ak chcete mať vlastnú aplikáciu (samozrejme s vaším logom a farbami :) a dostupnu na Apple a Androide ), kde si vaši zákazníci môžu objednať taxi bez toho, aby vám volali, kontaktujte nás prostredníctvom emailu ',
    pleaseContactUs2: ' a my vám pomôžeme to uskutočniť.'
  },
  taxiosDriverApp: {
    header: 'Nastavenia aplikácie vodiča Taxios',
    minLoggedDrivers: 'Minimálny počet prihlásených vodičov',
    maxDriversOnBreak: 'Maximálny počet vodičov na prestávke',
    maxDriversDisabledCalls: 'Maximálny počet vodičov s deaktivovanými hovormi',
    showEachOtherStatistics: 'Zobraziť štatistiky ostatných',
    showEachOtherStatisticsDescription: 'Ak je vybrané "Áno", vodiči môžu vidieť štatistiky ostatných vodičov v postrannom menu pod "Naši vodiči". Ak je vybrané "Nie", štatistiky pre nich nebudú viditeľné.',
    voiceChatButtonMode: 'Režim tlačidla hlasového chatu',
    voiceChatButtonModeDescription: 'Keď je vybraté "PTT", vodič musí tlačidlo stlačiť a držať, keď chce hovoriť, a uvoľniť, keď chce prestat hovoriť. Keď je vybraté "TOGGLE", jedno stlačenie aktivuje hlasový chat na určený čas a potom sa automaticky ukončí.',
    voiceChatToggleTimeLimiter: 'Časový limit hlasového chatu',
    voiceChatToggleTimeLimiterDescription: 'Nastaví dobu (v sekundách), počas ktorej zostane hlasový chat aktívny v režime TOGGLE po stlačení tlačidla.',
    maxAllowedCancels: 'Maximálny počet povolených zrušení',
    maxAllowedCancelsDescription: 'Obmedzuje, koľkokrát môže vodič zrušiť objednávku a obnoviť svoje prvé miesto v poradí. Po dosiahnutí limitu zostane na konci poradia a musí čakať na ďalšie kolo.',
    maxAllowedForwards: 'Maximálny počet povolených presmerovaní',
    maxAllowedForwardsDescription: 'Obmedzuje, koľkokrát môže vodič poslať objednávku kolegovi a obnoviť svoje prvé miesto v poradí. Po dosiahnutí limitu zostane na konci poradia a musí čakať na ďalšie kolo.',
    maxAllowedMissedCalls: 'Maximálny počet povolených zmeškaných hovorov',
    maxAllowedMissedCallsDescription: 'Obmedzuje, koľko hovorov môže vodič zmeškať, kým nebude presunutý na koniec poradia. Po dosiahnutí limitu musí vodič čakať na ďalšie kolo.',
    maxAllowedTransactionsTogether: 'Maximálny počet povolených transakcií naraz',
    maxAllowedLengthOfOrderInSecondsToResetQueue: 'Maximálna dĺžka objednávky (v sekundách) na resetovanie fronty',
    maxAllowedLengthOfOrderInSecondsToResetQueueDescription:
      'Nastaví časový limit (v sekundách), počas ktorého môže vodič zrušiť objednávku a byť presunutý späť na svoje pôvodné prvé miesto v poradí. Ak vodič pracuje na objednávke dlhšie ako tento limit, zrušenie objednávky neobnoví jeho pozíciu v poradí.',
    pushToTalk: 'Stlačte a hovorte',
    toggleToTalk: 'Stlačte raz, potom hovorte až do vypršania časového limitu',
    driversManagement: 'Nastavenia vodičov',
    voiceChatSettings: 'Nastavenia hlasového chatu',
    phoneQueueSettings: 'Nastavenia telefonickej fronty',
    lastUpdated: 'Naposledy zmenené',
    showCustomerNumberToTheDriver: 'Zobraziť číslo zákazníka vodičovi',
    showCustomerNumberToTheDriverDescription: 'Ak je vybraté "Áno", vodiči môžu vidieť číslo zákazníka v aplikácii. Ak je vybraté "Nie", uvidia len tlačidlo pre spätné volanie.',
    anybodyCanEditPlannedOrder: 'Každý vodič môže upravovať plánované objednávky',
    anybodyCanEditPlannedOrderDescription: 'Ak je vybraté "Áno", každý vodič môže upraviť akúkoľvek plánovanú objednávku. Ak je vybraté "Nie", môže objednávku upraviť len administrátor alebo vodič, ktorý ju vytvoril.',
    allowDriverToSaveCustomer: 'Povoliť vodičovi uložiť zákazníka',
    allowDriverToSaveCustomerDescription: 'Ak je vybratá možnosť "Áno", vodiči budú môcť ukladať telefónne čísla zákazníkov do vášho telefónneho zoznamu.',
    allowDriverToBlockCustomer: 'Povoliť vodičovi zablokovať zákazníka',
    allowDriverToBlockCustomerDescription: 'Ak je vybratá možnosť "Áno", vodiči budú mať možnosť zablokovať telefónne číslo zákazníka pri ukladaní do vášho telefónneho zoznamu. Ak bude zákazník zablokovaný, pri ďalšom volaní sa nedovolá do vašej taxislužby.'
  },
  settings: {
    admins: 'Administrátori',
    companyDetails: 'Detaily spoločnosti',
    invoiceDetails: 'Fakturačné údaje',
    myInvoices: 'Moje faktury',
    licenses: 'Poplatky za licencie',
    name: 'Meno taxislužby',
    companyName: 'Názov spoločnosti',
    companyAddress: 'Adresa',
    companyAddress2: 'Adresa 2',
    companyAddress3: 'Adresa 3',
    companyCity: 'Mesto',
    companyPostCode: 'PSČ',
    companyEmail: 'Email',
    companyPhone: 'Telefón',
    companyRegisteredFrom: 'Registrované od',
    companyCountry: 'Krajina',
    companyIdentificationNumber: 'Identifikačné číslo',
    companyTaxNumber: 'DIČ',
    invoiceLanguage: 'Jazyk faktúry',
    password: 'Heslo',
    role: 'Rola',
    email: 'Email',
    options: 'Možnosti',
    admin: 'Admin',
    dispatchAgent: 'Dispečer',
    lastLoggedIn: 'Posledné prihlásenie',
    addAdmin: 'Pridať nového admina',
    licensePrice: 'Štandardná cena',
    perVehicle: '/ za vozidlo',
    vehicleLicense: 'Licencia na vozidlo',
    vehicleLicenseInfo: 'Pridávanie a odoberanie licencií vozidiel je automatické. Počet licencií je rovnaký ako počet vozidiel, ktoré používate. Pre úpravu počtu licencií môžete zmeniť počet vozidiel stlačením tlačidla nižšie.',
    manageMyVehicleLicenses: 'Upraviť vozidlá',
    countAtPrice: 'Máte {count} vozidlá za cenu: {price} €',
    totalLicenses: 'Celkový počet licencií: {count}',
    totalPrice: 'Celková cena: {price} €',
    dispatcherLicense: 'Licencia pevneho dispečera',
    dispatcherInfo: 'Manuálna správa objednáviek pomocou pevného dispatchera. Lutujeme, ale aktivácia tejto funkcie nie je momentálne dostupná. Prosím kontaktujte našu technickú podporu.',
    yourPrice: 'Vaša cena: zdarma (zahrnuté v štartovacom balíku)',
    fleetManagerLicense: 'Licencia manažéra flotily (commander)',
    fleetManagerInfo: 'Správa vozidiel pomocou manažéra flotily Vám umožní aktivovať notifikácie pre nadchádzajúce servisné udalosti, alebo pre upozornenia na expiráciu diaľničnej známky.',
    callRecordingLicense: 'Licencia nahrávania hovorov',
    callRecordingInfo: 'Automatické nahrávanie prichádzajúcich hovorov pre zlepšenie kvality Vašich služieb ako aj pre podrobnú analýzu objednávok. Nahrávky hovorov sú dostupné v detailoch objednávok po dobu 90 dní.',
    callRecordingAlert:
      'Pozor! Zakúpením a používaním tohto produktu Vám vzniká povinnosť informovať Vašich zákazníkov o nahrávaní hovorov. Taktiež musíte dodržiavať zákony a predpisy v oblasti ochrany osobných údajov. Taxios s.r.o. nenesie žiadnu zodpovednosť za porušenie zákonov a predpisov v oblasti ochrany osobných údajov z Vašej strany. Pre zapnutie informácie o nahrávaní hovorov pre zákazníkov, kontaktujte technickú podporu!',
    adminName: 'Meno administrátora',
    modal: {
      titleAdd: 'Pridať administrátora',
      titleEdit: 'Upraviť administrátora',
      removeAdmin: 'Odstrániť administrátora'
    }
  },
  taxiosVCR: {
    header: 'Integrácia VRP 2',
    subheader: '(VRP Slovensko)',
    enableVRP: 'Povoliť integráciu VRP do aplikácie Taxios Driver?',
    VRPMethod: 'Spôsob pripojenia do VRP 2',
    openVRPApp: 'Otvoriť VRP2 po dokončení objednávky',
    directIntegration: 'VRP záznam odošleme za vás',
    VRPUserName: 'VRP 2 používateľské meno',
    VRPPassword: 'VRP 2 heslo',
    vrpAppOpenInstruction: 'Po dokončení objednávky aplikácia Taxios Driver automaticky otvorí aplikáciu VRP2, čo umožní vodičovi vytvoriť záznam, vytlačiť doklad a ďalšie úkony.',
    vrpDirectInstruction:
      'Po dokončení objednávky bude vodič vyzvaný, aby zadal cenu jazdného (uistite sa, že nastavenia taríf sú správne nakonfigurované!). Následne tieto údaje odošleme do VRP 2 a vrátime účtenku vodičovi v aplikácii Taxios Driver, kde ju môže uložiť ako snímku obrazovky alebo vytlačiť, ak je k dispozícii Bluetooth tlačiareň',
    vrpDirectInstructionError: 'Bohužiaľ, táto metóda VRP2 nie je momentálne k dispozícii. Pre viac informácií nás prosím kontaktujte prostredníctvom emailu nebo telefonu.',
    vrpAppOpenAppMustBeInstalled: 'Všetci vodiči musia mať na svojich telefónoch nainštalovanú aplikáciu VRP2 z obchodu s aplikáciami'
  },
  fareSettings: {
    header: 'Nastavenia taríf'
  },
  help: {
    header: 'Pomoc',
    status: 'Status',
    socketStatus: 'Pripojenie k serveru ',
    radioSocketStatus: 'Pripojenie k vysielačkovému serveru '
  },
  logout: {
    header: 'Odhlásiť sa',
    areYouSure: 'Naozaj sa chcete odhlásiť?',
    logoutButton: 'Odhlásiť sa'
  },
  taxiosNotifications: {
    header: 'Nastavenia upozornení',
    notificationsEmails: 'Vaše e-mailové adresy, kam posielame e-mailové upozornenia',
    drivers: 'Vodiči',
    notifyOnDriverLogin: 'Upozorniť na prihlásenie vodiča',
    notifyOnDriverLogout: 'Upozorniť na odhlásenie vodiča',
    notifyWhenDriverOnBreak: 'Upozorniť, keď vodič začne prestávku',
    notifyWhenDriverEndsBreak: 'Upozorniť, keď vodič ukončí prestávku',
    notificationsEmailsForDrivers: 'E-maily pre upozornenia o vodičoch',
    orders: 'Objednávky',
    notifyOnMissedOrderCall: 'Upozorniť, keď vodič zmešká hovor',
    notifyOnMissedPlannedOrder: 'Upozorniť, keď vodič nevykoná akciu na plánovanú objednávku',
    notifyOnPlannedOrderCreated: 'Upozorniť, keď vodič vytvorí plánovanú objednávku',
    notifyOnCompletedOrder: 'Upozorniť, keď vodič dokončí objednávku',
    taxiosSentinel: 'Taxios Sentinel',
    taxiosSentinelNotifications: 'Upozornenia Taxios Sentinel',
    taxiosSentinelNotificationsDescription: `
      Dostávajte včasné upozornenia od Taxios Sentinel, aby ste mali údržbu vozidla pod kontrolou. 
      <br /><br />
      <strong>Výmena oleja:</strong> Upozornenia budú zasielané v nasledujúcich intervaloch, odpočítavajúc do výmeny oleja:
      <ul>
        <li>1. upozornenie pri zostávajúcich 2000 km</li>
        <li>2. upozornenie pri zostávajúcich 1000 km</li>
        <li>3. upozornenie pri zostávajúcich 100 km</li>
        <li>Denné pripomienky budú pokračovať, kým nebude výmena oleja potvrdená v Taxios Sentinel</li>
      </ul>
      <br />
      <strong>STK/Emisie:</strong> Upozornenia budú zasielané:
      <ul>
        <li>1. upozornenie 2 mesiace pred</li>
        <li>2. upozornenie 1 mesiac pred</li>
        <li>3. upozornenie 1 týždeň pred</li>
      </ul>
    `
  }
}
